import { LessonChip } from "@/components/lists/LessonsListView/LessonStatusChip";
import { BranchSectionView } from "@/components/sections/BranchSectionView";
import DescriptionSectionView from "@/components/sections/DescriptionSectionView";
import { GroupSectionView } from "@/components/sections/GroupSectionView";
import { TeacherSectionView } from "@/components/sections/TeacherSectionView";
import { lessonHasTeacherReplacement } from "@/modules/app";
import { Stack, Typography } from "@mui/joy";
import { FormattedDate, FormattedMessage } from "react-intl";

export default function LessonView({
  lesson,
  children,
}: React.PropsWithChildren<{
  lesson: LinkedLesson;
}>) {
  return (
    <Stack gap={3}>
      <Stack sx={{ gap: 1 }} pb={2}>
        <Typography level="h3">{lesson.group?.name}</Typography>
        <Typography level="title-sm">
          <FormattedMessage
            defaultMessage="{st, date, ::H:mm}-{et, date, ::H:mm}"
            values={{
              st: lesson.startDateTime,
              et: lesson.endDateTime,
            }}
          />
          {" · "}
          <FormattedDate
            value={lesson.startDateTime}
            month="short"
            day="numeric"
            weekday="long"
          />
        </Typography>
        <LessonChip lesson={lesson} />
      </Stack>
      <BranchSectionView branch={lesson.group?.branch} />
      <GroupSectionView group={lesson.group} />
      <TeacherSectionView
        teacher={lesson.teacher}
        replacedTeacher={
          lessonHasTeacherReplacement(lesson) && lesson.group?.teacher
        }
      />
      <DescriptionSectionView content={lesson.group?.description} />
      {children}
    </Stack>
  );
}

import { Card, Link, Stack, Typography } from "@mui/joy";
import { format } from "date-fns";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import { EmptyListView } from "../EmptyListView";
import { PassStatusChip } from "./PassStatusChip";

const formatDate = (date: Date) => format(date, "d.MM.yy");

export const PassesListView = ({
  passes,
  onClick,
  showBookingHours,
}: {
  passes: PartialPass[];
  onClick?: (pass: PartialPass) => void;
  showBookingHours?: boolean;
}) => {
  if (passes.length === 0) return <EmptyListView />;

  return (
    <Stack gap={2}>
      {passes.map((pass) => {
        if (!pass.kind?.name) return null;

        return (
          <Card key={pass.recId} sx={{ gap: 1, bgcolor: "white" }}>
            <Link
              onClick={(e) => {
                if (onClick) {
                  e.preventDefault();
                  e.stopPropagation();
                  onClick(pass);
                }
              }}
              component={RouterLink}
              to={`/passes/${pass.recId}`}
              underline="none"
              fontWeight="md"
              fontSize="md"
              color="neutral"
              textColor="text.primary"
              overlay
            >
              {pass.kind?.name}
            </Link>
            {((pass.activationDate && pass.deactivationDate) ||
              pass.active) && (
              <Typography level="body-sm">
                {pass.activationDate && pass.deactivationDate && (
                  <>
                    {formatDate(pass.activationDate)}
                    {" - "}
                    {formatDate(pass.deactivationDate)}
                  </>
                )}
                {pass.active && (
                  <>
                    {" · "}
                    <FormattedMessage
                      defaultMessage="{count, plural, one {{count} час} few {{count} часа} many {{count} часов} other {{count} ч.}}"
                      values={{
                        count: pass.remainingVisitHours,
                      }}
                    />
                  </>
                )}
                {showBookingHours && (
                  <>
                    {" · "}
                    <FormattedMessage
                      defaultMessage="{count, plural, one {{count} час} few {{count} часа} many {{count} часов} other {{count} ч.}} без записи"
                      values={{
                        count: pass.remainingBookingHours,
                      }}
                    />
                  </>
                )}
              </Typography>
            )}
            <PassStatusChip pass={pass} />
          </Card>
        );
      })}
    </Stack>
  );
};

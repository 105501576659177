import { Location } from "@/__generated__/graphql";
import DescriptionSectionView from "@/components/sections/DescriptionSectionView";
import { Box, Button } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function SchoolRulesView({
  location,
  onShowContractsClick,
}: {
  location: Location;
  onShowContractsClick: () => void;
}) {
  return (
    <Box>
      <DescriptionSectionView content={location.schoolRules} />
      <Button fullWidth onClick={onShowContractsClick}>
        <FormattedMessage defaultMessage="Ознакомиться с договором" />
      </Button>
    </Box>
  );
}

import { ArrowBackIos } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/joy";

export default function Header({
  children,
  header,
  onBack,
}: React.PropsWithChildren<{
  onBack?: () => void;
  header?: React.ReactNode;
}>) {
  const theme = useTheme();

  return (
    <Box
      bgcolor="white"
      sx={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        zIndex: theme.zIndex.table,
      }}
    >
      {onBack && (
        <ArrowBackIos
          onClick={onBack}
          sx={{
            height: theme.fontSize.xl3,
            p: 2,
            position: "absolute",
            boxSizing: "content-box",
          }}
        />
      )}

      {header ? (
        header
      ) : (
        <Typography level="h4" textAlign="center" sx={{ my: 0, p: 2 }}>
          {children}
        </Typography>
      )}
    </Box>
  );
}

import { Chip, Stack, Typography, useTheme } from "@mui/joy";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export function LessonsSectionView({
  lessons,
  label,
}: {
  lessons?: LinkedLesson[];
  label?: React.ReactNode;
}) {
  const theme = useTheme();

  if (!lessons) return null;

  return (
    <Stack gap={2} pb={2}>
      {label ?? (
        <Typography level="h4">
          <FormattedMessage defaultMessage="Ближайшие занятия" />
        </Typography>
      )}
      <Stack direction="row" gap={1} sx={{ flexWrap: "wrap" }}>
        {lessons.slice(0, 8).map((lesson) => (
          <Chip
            key={lesson.recId}
            variant="outlined"
            slotProps={{
              action: {
                component: Link,
                to: `/lessons/${lesson.recId}`,
                sx: {
                  "--joy-palette-neutral-outlinedHoverBg": "#fff",
                  "--joy-palette-neutral-outlinedActiveBg": "#fff",
                },
              },
            }}
            sx={{
              p: 1,
              textAlign: "center",
              borderRadius: theme.radius.xl,
              "--joy-palette-background-surface": "#fff",
            }}
          >
            <Typography level="body-sm">
              <FormattedMessage
                defaultMessage="{time, date, ::EE, MMM d}"
                values={{
                  time: lesson.startDateTime,
                }}
              />
              <br />
              <FormattedMessage
                defaultMessage="{time, date, ::H:mm}"
                values={{
                  time: lesson.startDateTime,
                }}
              />
            </Typography>
          </Chip>
        ))}
      </Stack>
    </Stack>
  );
}

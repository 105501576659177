import { Branch } from "@/__generated__/graphql";
import DescriptionSectionView from "@/components/sections/DescriptionSectionView";
import { MediaSectionView } from "@/components/sections/MediaSectionView";
import { formatPhone } from "@/helpers";
import {
  Instagram,
  LocationOn,
  Phone,
  Schedule,
  Telegram,
  WhatsApp,
} from "@mui/icons-material";
import { Button, IconButton, Link, Stack, Typography } from "@mui/joy";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

export function BranchView({
  branch,
  onChoose,
  onBack,
}: {
  branch: Branch;
  onChoose?: () => void;
  onBack?: () => void;
}) {
  const formattedPhone = formatPhone(branch.phone);

  return (
    <Stack gap={2}>
      <Typography level="title-lg">{branch.name}</Typography>

      {branch.address && (
        <Stack gap={2} direction="row">
          <LocationOn />
          {branch.googleMapsLink ? (
            <Link
              component={RouterLink}
              to={branch.googleMapsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {branch.address}
            </Link>
          ) : (
            <Typography>{branch.address}</Typography>
          )}
        </Stack>
      )}

      {branch.phone && (
        <Stack gap={2} direction="row">
          <Phone />
          <Stack gap={1}>
            <Link
              component={RouterLink}
              to={`tel://+${branch.phone}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {formattedPhone}
            </Link>
            <Stack gap={2} direction="row" flexWrap="wrap">
              {branch.instagramLink && (
                <IconButton
                  component="a"
                  aria-label="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={branch.instagramLink}
                  sx={{
                    backgroundColor: "#bc2a8d",
                  }}
                >
                  <Instagram sx={{ color: "#fff" }} />
                </IconButton>
              )}
              {branch.telegramLink && (
                <IconButton
                  component="a"
                  aria-label="Telegram"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={branch.telegramLink}
                  sx={{
                    backgroundColor: "#0088cc",
                  }}
                >
                  <Telegram sx={{ color: "#fff" }} />
                </IconButton>
              )}
              {branch.whatsAppLink && (
                <IconButton
                  component="a"
                  aria-label="WhatsApp"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={branch.whatsAppLink}
                  sx={{
                    backgroundColor: "#25d366",
                  }}
                >
                  <WhatsApp sx={{ color: "#fff" }} />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Stack>
      )}

      {branch.schedule && (
        <Stack gap={2} direction="row">
          <Schedule />
          <Typography whiteSpace="pre">{branch.schedule}</Typography>
        </Stack>
      )}

      <MediaSectionView media={branch.media} />

      {branch.description && (
        <Typography level="body-sm">
          <DescriptionSectionView content={branch.description} />
        </Typography>
      )}

      {onChoose && (
        <Button onClick={onChoose}>
          <FormattedMessage defaultMessage="Выбрать филиал" />
        </Button>
      )}

      {onBack && (
        <Button onClick={onBack} variant="outlined">
          <FormattedMessage defaultMessage="Назад" />
        </Button>
      )}
    </Stack>
  );
}

import { PassStatus } from "@/__generated__/graphql";
import { Chip } from "@mui/joy";
import { useIntl } from "react-intl";

export function PassStatusChip({ pass }: { pass: PartialPass }) {
  const intl = useIntl();

  return {
    [PassStatus.Active]: (
      <Chip color="success">
        {intl.formatMessage({ defaultMessage: "Активен" })}
      </Chip>
    ),
    [PassStatus.Expired]: (
      <Chip color="warning">
        {intl.formatMessage({ defaultMessage: "Истек" })}
      </Chip>
    ),
    [PassStatus.Frozen]: (
      <Chip color="primary">
        {intl.formatMessage({ defaultMessage: "Заморожен" })}
      </Chip>
    ),
    [PassStatus.NoHours]: (
      <Chip color="neutral">
        {intl.formatMessage({ defaultMessage: "Закончились часы" })}
      </Chip>
    ),
    [PassStatus.Refunded]: (
      <Chip color="danger">
        {intl.formatMessage({ defaultMessage: "Возврат" })}
      </Chip>
    ),
    [PassStatus.NotActive]: (
      <Chip color="neutral">
        {intl.formatMessage({ defaultMessage: "Не активирован" })}
      </Chip>
    ),
  }[pass.status];
}

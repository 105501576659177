import env from "@/env";
import { Alert, Button, Stack, Typography } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function CancelLessonWithPenaltyView({
  onSubmit,
  onClose,
}: {
  lesson: LinkedLesson;
  onSubmit: () => void;
  onClose: () => void;
}) {
  return (
    <Stack gap={2}>
      <Typography level="title-md">
        <FormattedMessage defaultMessage="Отмена записи" />
      </Typography>

      <Alert color="danger">
        <FormattedMessage
          defaultMessage="Занятие будет списано, поскольку до начала занятия осталось менее {count}"
          values={{
            count: (
              <FormattedMessage
                defaultMessage="{count, plural, one {{count} час} few {{count} часа} many {{count} часов} other {{count} ч.}}"
                values={{
                  count: env.REACT_APP_LESSON_CANCELLATION_MIN_HOURS,
                }}
              />
            ),
          }}
        />
      </Alert>

      <Stack gap={1}>
        <Button onClick={onSubmit} color="danger">
          <FormattedMessage defaultMessage="Отменить запись и списать занятие" />
        </Button>
        <Button onClick={onClose} variant="outlined">
          <FormattedMessage defaultMessage="Закрыть" />
        </Button>
      </Stack>
    </Stack>
  );
}

import { BranchSectionView } from "@/components/sections/BranchSectionView";
import DescriptionSectionView from "@/components/sections/DescriptionSectionView";
import { LessonsSectionView } from "@/components/sections/LessonsSectionView";
import { TeacherSectionView } from "@/components/sections/TeacherSectionView";
import { Schedule } from "@mui/icons-material";
import { Stack, Typography } from "@mui/joy";
import { addHours, set, setDay } from "date-fns";
import { FormattedMessage, useIntl } from "react-intl";

export default function GroupView({
  group,
  upcomingLessons,
}: {
  group: LinkedGroup;
  upcomingLessons: LinkedLesson[];
}) {
  const intl = useIntl();
  const startTime = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: group.startTimeSeconds,
  });

  return (
    <Stack gap={3}>
      <Stack gap={1}>
        <Typography level="h3">{group?.name}</Typography>
        <Stack direction="row" gap={1}>
          <Schedule />
          <Typography level="body-md">
            {group.dayOfWeeks
              .map((day) =>
                intl
                  .formatDate(setDay(new Date(), day), {
                    weekday: "short",
                  })
                  .toUpperCase()
              )
              .join("/")}{" "}
            <FormattedMessage
              defaultMessage="{st, date, ::H:mm}-{et, date, ::H:mm}"
              values={{
                st: startTime,
                et: addHours(startTime, group.durationHours),
              }}
            />
          </Typography>
        </Stack>
        <Typography level="body-md" textColor="neutral.400">
          <FormattedMessage defaultMessage="Постоянных учениц:" />{" "}
          {group.autoBookingPassesRecIds.length}/{group.maxSeats}
        </Typography>
      </Stack>
      <BranchSectionView branch={group?.branch} />
      <TeacherSectionView teacher={group.teacher} />
      <LessonsSectionView lessons={upcomingLessons} />
      <DescriptionSectionView
        content={group.description}
        label={
          <Typography level="h4">
            <FormattedMessage defaultMessage="О группе" />
          </Typography>
        }
      />
    </Stack>
  );
}

import { Branch } from "@/__generated__/graphql";
import { Place } from "@mui/icons-material";
import { Box, CardContent, Stack, Typography } from "@mui/joy";

export function BranchSectionView({ branch }: { branch?: Branch | null }) {
  if (!branch) return null;

  return (
    <CardContent orientation="horizontal">
      <CardContent sx={{ alignItems: "center", flexDirection: "row" }}>
        <Box>
          <Stack direction="row" gap={1}>
            <Place />

            <Stack>
              <Typography level="body-md">{branch.name}</Typography>

              <Typography level="body-sm" textColor="neutral.400">
                {branch.address}
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </CardContent>
    </CardContent>
  );
}

import React from "react";
import { useMatches, useNavigate } from "react-router-dom";
import { TabChoices, TabsLayoutView } from "./TabsLayoutView";

export function TabsLayout({
  children,
  title,
  header,
}: React.PropsWithChildren<{
  title?: React.ReactNode;
  header?: React.ReactNode;
}>) {
  const navigate = useNavigate();
  const { handle } = (useMatches().find((match) => match.handle) || {}) as {
    handle?: {
      tab?: TabChoices;
    };
  };

  const onChangeTab = (tab: TabChoices) =>
    navigate(
      {
        [TabChoices.Home]: "/home",
        [TabChoices.Schedule]: "/",
        [TabChoices.Settings]: "/settings",
      }[tab]
    );

  return (
    <TabsLayoutView
      currentTab={handle?.tab || TabChoices.Home}
      onChangeTab={onChangeTab}
      title={title}
      header={header}
    >
      {children}
    </TabsLayoutView>
  );
}

import { Branch } from "@/__generated__/graphql";
import { getHours, isSameDay } from "date-fns";
import { Filters } from "../../types";

export const filterLessons = (
  lessons: LinkedLesson[],
  branch: Branch,
  filters: Partial<Filters>,
  date?: Date
) => {
  const filteredLessons = lessons.filter(
    (lesson) =>
      branch.recId === lesson.group?.branchRecId &&
      (!filters.teachers?.length ||
        filters.teachers.some(
          (teacher) =>
            teacher.recId === lesson.teacherRecId ||
            teacher.recId === lesson.group?.teacherRecId
        )) &&
      (!filters.groupTypes?.length ||
        filters.groupTypes.some(
          (groupType) =>
            groupType.level === lesson.group?.level &&
            groupType.direction === lesson.group?.direction
        )) &&
      (!filters.timeSlots?.length ||
        filters.timeSlots.some(
          (time) =>
            time.from <= getHours(lesson.startDateTime) &&
            getHours(lesson.startDateTime) < time.to
        ))
  );

  if (date) {
    return filteredLessons.filter((lesson) =>
      isSameDay(lesson.startDateTime, date)
    );
  }

  return filteredLessons;
};

import { useSendVerificationCodeMutation } from "@/__generated__/graphql";
import env from "@/env";
import SignUpView from "@/modules/auth/views/SignUpView";

export default function SignUp({
  onNext,
  name,
  surname,
  onChangeName,
  onChangeSurname,
  onBack,
  phone,
}: {
  phone: string;
  onChangeName: (name: string) => void;
  onChangeSurname: (surname: string) => void;
  name: string;
  surname: string;
  onNext: () => void;
  onBack: () => void;
}) {
  const [sendCode] = useSendVerificationCodeMutation();
  const parsedPhone = `+${phone.replace(/\D/g, "")}`;

  const onSubmit = async () => {
    await sendCode({
      variables: {
        phone: parsedPhone,
        locationId: env.REACT_APP_LOCATION_ID,
        signInAs: false,
      },
    });

    onNext();
  };

  return (
    <SignUpView
      onChangeName={onChangeName}
      onChangeSurname={onChangeSurname}
      onSubmit={onSubmit}
      name={name}
      surname={surname}
      onBack={onBack}
    />
  );
}

import { getHours } from "date-fns";
import { IntlShape } from "react-intl";

export const getTimeSlot = (intl: IntlShape) => (lesson: LinkedLesson) => {
  const dayHours = getHours(lesson.startDateTime);

  if (dayHours < 12)
    return {
      label: intl.formatMessage({
        defaultMessage: "Утро",
      }),
      from: 8,
      to: 12,
    };

  if (dayHours >= 12 && dayHours < 17)
    return {
      label: intl.formatMessage({
        defaultMessage: "День",
      }),
      from: 12,
      to: 17,
    };

  return {
    label: intl.formatMessage({
      defaultMessage: "Вечер",
    }),
    from: 17,
    to: 23,
  };
};

import { Pass } from "@/__generated__/graphql";
import { LessonsListView } from "@/components/lists/LessonsListView/LessonsListView";
import { PassStatusChip } from "@/components/lists/PassesListView/PassStatusChip";
import { GroupSectionView } from "@/components/sections/GroupSectionView";
import { CardGiftcard, Payments } from "@mui/icons-material";
import { Alert, Box, Button, Grid, Stack, Typography } from "@mui/joy";
import { format } from "date-fns";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import PassDetailItemView from "./PassDetailItemView";

const formatDate = (date: Date) => format(date, "d.MM.yy");

export default function PassView({
  pass,
}: {
  pass: Pass & {
    upcomingLessons: LinkedLesson[];
    visitedLessons: LinkedLesson[];
    penaltiedLessons: LinkedLesson[];
  };
}) {
  return (
    <Stack gap={4}>
      <Stack gap={1}>
        <Typography level="h4">{pass.kind?.name}</Typography>
        {((pass.activationDate && pass.deactivationDate) || pass.active) && (
          <Typography level="body-sm">
            {pass.activationDate && pass.deactivationDate && (
              <>
                {formatDate(pass.activationDate)}
                {" - "}
                {formatDate(pass.deactivationDate)}
                {pass.active && " · "}
              </>
            )}
            {pass.active && (
              <FormattedMessage
                defaultMessage="{count, plural, one {{count} час} few {{count} часа} many {{count} часов} other {{count} ч.}}"
                values={{
                  count: pass.remainingVisitHours,
                }}
              />
            )}
          </Typography>
        )}
        <PassStatusChip pass={pass} />
      </Stack>

      <Stack gap={1}>
        <Typography level="title-lg">
          <FormattedMessage defaultMessage="Автоматическая запись" />
        </Typography>

        {pass.autoBookingGroup ? (
          <GroupSectionView group={pass.autoBookingGroup} />
        ) : (
          <Alert size="sm" sx={{ color: "neutral.600" }}>
            <FormattedMessage defaultMessage="Для активации автоматической записи пожалуйста обратитесь к администратору" />
          </Alert>
        )}
      </Stack>

      <Stack gap={1}>
        <Typography level="title-lg">
          <FormattedMessage defaultMessage="Ближайшие занятия" />
        </Typography>
        {pass.upcomingLessons.length === 0 ? (
          <>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography color="neutral">
                <FormattedMessage defaultMessage="Нет записей" />
              </Typography>
            </Box>

            {pass.active && (
              <Button variant="outlined" component={RouterLink} to="/">
                <FormattedMessage defaultMessage="Записаться" />
              </Button>
            )}
          </>
        ) : (
          <LessonsListView lessons={pass.upcomingLessons} />
        )}
      </Stack>

      <Stack gap={1}>
        <Typography level="title-lg">
          <FormattedMessage defaultMessage="Посещения" />
        </Typography>
        <LessonsListView lessons={pass.visitedLessons} />
      </Stack>

      {pass.penaltiedLessons.length > 0 && (
        <Stack gap={1}>
          <Typography level="title-lg">
            <FormattedMessage defaultMessage="Списанные занятия" />
          </Typography>
          <LessonsListView lessons={pass.penaltiedLessons} />
        </Stack>
      )}

      {pass.freezes.length > 0 && (
        <Stack gap={1}>
          <Typography level="title-lg">
            <FormattedMessage defaultMessage="Заморозки" />
          </Typography>
          {pass.freezes.map((freeze) => (
            <PassDetailItemView
              key={freeze.id}
              title={
                <FormattedMessage
                  defaultMessage="Заморозка на {count} дн."
                  values={{ count: freeze.daysCount }}
                />
              }
              secondaryText={formatDate(freeze.startDate)}
              endDecorator={
                !freeze.price ? (
                  <CardGiftcard />
                ) : (
                  <Grid container alignItems="center" gap={1}>
                    <Payments />
                    <Typography>{freeze.price}</Typography>
                  </Grid>
                )
              }
            />
          ))}
        </Stack>
      )}

      {pass.sickLists.length > 0 && (
        <Stack gap={1}>
          <Typography level="title-lg">
            <FormattedMessage defaultMessage="Справки" />
          </Typography>

          {pass.sickLists.map((sickList) => (
            <PassDetailItemView
              key={sickList.id}
              title={
                <FormattedMessage
                  defaultMessage="Справка на {count} дн."
                  values={{ count: sickList.daysCount }}
                />
              }
              secondaryText={`${formatDate(sickList.startDate)} - ${formatDate(
                sickList.endDate
              )}`}
            />
          ))}
        </Stack>
      )}

      {pass.refund && (
        <Stack gap={1}>
          <Typography level="title-lg">
            <FormattedMessage defaultMessage="Возврат" />
          </Typography>

          <PassDetailItemView
            title={<FormattedMessage defaultMessage="Возврат абонемента" />}
            secondaryText={formatDate(pass.refund.date)}
            endDecorator={
              <Grid container alignItems="center" gap={1}>
                <Payments />
                <Typography>{pass.refund.amount}</Typography>
              </Grid>
            }
          />
        </Stack>
      )}
    </Stack>
  );
}

import { Branch } from "@/__generated__/graphql";
import { useAppContext } from "@/modules/app";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { Filters, FiltersOptions } from "../types";
import { filterLessons } from "./helpers/filterLessons";
import { getFiltersOptionsFn } from "./helpers/getFiltersOptionsFn";
import { getDefaultBranch } from "./helpers/getLastPass";

interface FiltersContextProps {
  lessons: LinkedLesson[];
  filtersOptions: FiltersOptions;

  currentBranch: Branch;
  currentDate: Date;
  setCurrentBranch: (branch: Branch) => void;
  setCurrentDate: (date: Date) => void;

  filters: Filters;
  nextFilters: Filters;

  resetFilters: () => void;
  setNextFilters: (value: Partial<Filters>) => void;
  applyNextFilters: () => void;
}

const FiltersContext = createContext<FiltersContextProps>(null as any);

const defaultFilters: Filters = {
  teachers: [],
  groupTypes: [],
  timeSlots: [],
};

export const FiltersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { allBranches, allUpcomingLessons, allPasses } = useAppContext();
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const defaultBranch = getDefaultBranch(allBranches, allPasses);

  const [filters, setFilters] = useState<Filters>(
    location.state?.filters ?? defaultFilters
  );

  const [nextFilters, setNextFilters] = useState<Filters>(filters);

  const [currentBranch, setCurrentBranch] = useState<Branch>(
    location.state?.currentBranch ?? defaultBranch
  );

  const [currentDate, setCurrentDate] = useState<Date>(
    location.state?.currentDate ?? new Date()
  );

  const getFiltersOptions = getFiltersOptionsFn(
    intl,
    allBranches,
    allUpcomingLessons
  );

  const [filtersOptions, setFiltersOptions] = useState<FiltersOptions>(
    getFiltersOptions(currentBranch, filters)
  );

  useEffect(() => {
    navigate(`/`, {
      replace: true,
      state: { filters, currentBranch, currentDate },
      preventScrollReset: true,
    });
  }, [currentDate, currentBranch, filters, navigate]);

  const onSetNextFilters = (partialFilters: Partial<Filters>) => {
    const newFilters = {
      ...nextFilters,
      ...partialFilters,
    };
    setFiltersOptions(getFiltersOptions(currentBranch, newFilters));
    setNextFilters(newFilters);
  };

  const resetFilters = () => onSetCurrentBranch(currentBranch);
  const applyNextFilters = () => setFilters(nextFilters);

  const onSetCurrentBranch = (branch: Branch) => {
    setCurrentBranch(branch);
    setFilters(defaultFilters);
    setNextFilters(defaultFilters);
    setFiltersOptions(getFiltersOptions(branch, defaultFilters));
  };

  const lessons = filterLessons(
    allUpcomingLessons,
    currentBranch,
    filters,
    currentDate
  );

  return (
    <FiltersContext.Provider
      value={{
        lessons,
        filters,
        nextFilters,
        filtersOptions,
        currentDate,
        currentBranch,
        setNextFilters: onSetNextFilters,
        resetFilters,
        applyNextFilters,
        setCurrentBranch: onSetCurrentBranch,
        setCurrentDate,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => useContext(FiltersContext);

import { PageLayout } from "@/components/layouts/PageLayout";
import { useIntl } from "react-intl";
import { Navigate } from "react-router-dom";
import { useAppContext } from "../app";
import ProfileEdit from "./containers/ProfileEdit";

export default function ProfileEditPage() {
  const intl = useIntl();
  const { user } = useAppContext();

  if (!user) {
    return <Navigate to="/home" />;
  }

  return (
    <PageLayout
      title={intl.formatMessage({
        defaultMessage: "Профиль",
      })}
    >
      <ProfileEdit user={user} />
    </PageLayout>
  );
}

import { usePastLessonsQuery } from "@/__generated__/graphql";
import { extractGraphQLErrorMessage } from "@/helpers";
import { mapLessons, useAppContext } from "@/modules/app";
import { intersection } from "lodash";
import { MAX_PAST_LESSONS } from "../constants";
import UserLessonsView from "../views/UserLessonsView";

export default function UserLessons() {
  const { allUpcomingLessons, allPasses, allBranches } = useAppContext();

  const { data, error, loading } = usePastLessonsQuery({
    variables: {
      pageSize: MAX_PAST_LESSONS,
      offset: 0,
    },
  });

  const passesRecIds = allPasses?.map((pass) => pass.recId);
  const upcomingLinkedLessons = allUpcomingLessons.filter(
    (lesson) => intersection(passesRecIds, lesson.bookingsRecIds).length > 0
  );

  const pastLinkedLessons =
    data &&
    mapLessons(
      allBranches,
      data.pastUserLessons.teachers,
      data.pastUserLessons.groups,
      data.pastUserLessons.lessons,
      allPasses
    );

  return (
    <UserLessonsView
      upcomingLessons={{
        data: upcomingLinkedLessons,
      }}
      pastLessons={{
        data: pastLinkedLessons,
        error: error && extractGraphQLErrorMessage(error),
        loading,
      }}
    />
  );
}

import { Tune } from "@mui/icons-material";
import { Button, useTheme } from "@mui/joy";

export default function FiltersButtonView({
  onClick,
  active,
}: {
  onClick: () => void;
  active: boolean;
}) {
  const theme = useTheme();

  return (
    <Button
      color={active ? "primary" : "neutral"}
      variant="solid"
      size="md"
      style={{
        position: "fixed",
        bottom: "calc(5rem + env(safe-area-inset-bottom))",
        right: "1rem",
        zIndex: theme.zIndex.badge,
      }}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <Tune />
    </Button>
  );
}

import { Alert, Stack, Typography } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function BookLessonAuthView({
  children,
}: React.PropsWithChildren) {
  return (
    <Stack gap={3}>
      <Alert color="primary" sx={{ textAlign: "center", mx: 2 }}>
        <Typography level="title-md" sx={{ margin: "auto" }}>
          <FormattedMessage defaultMessage="Для записи необходимо авторизоваться" />
        </Typography>
      </Alert>
      {children}
    </Stack>
  );
}

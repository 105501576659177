import { useGroupQuery } from "@/__generated__/graphql";
import LoadingView from "@/components/layouts/LoadingView";
import NotFoundView from "@/components/layouts/NotFoundView";
import { useAppContext } from "@/modules/app";
import { useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";
import { getCachedLinkedGroup } from "../helpers/getCachedLinkedGroup";
import GroupView from "../views/GroupView";

export default function GroupContainer() {
  const { allUpcomingLessons } = useAppContext();
  const { groupId } = useParams<{ groupId: string }>();
  const client = useApolloClient();

  const cachedGroup = groupId && getCachedLinkedGroup(client, groupId);

  const { data, loading, error } = useGroupQuery({
    variables: {
      recId: String(groupId),
      hasCached: Boolean(cachedGroup),
    },
  });

  const upcomingLessons = allUpcomingLessons.filter(
    (lesson) => lesson.group?.recId === groupId
  );

  if (cachedGroup)
    return <GroupView group={cachedGroup} upcomingLessons={upcomingLessons} />;

  if (loading) return <LoadingView />;

  if (error || !data?.group) return <NotFoundView />;

  return <GroupView group={data.group} upcomingLessons={upcomingLessons} />;
}

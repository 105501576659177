import { Box, Modal, ModalDialog } from "@mui/joy";
import React from "react";

export function AppModal({
  open,
  onClose,
  children,
}: React.PropsWithChildren<{
  onClose: () => void;
  open: boolean;
}>) {
  return (
    <Modal open={open} onClose={onClose} disableEnforceFocus>
      <ModalDialog
        sx={(theme) => ({
          [theme.breakpoints.only("xs")]: {
            top: "unset",
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 0,
            transform: "none",
            maxWidth: "100vw",
          },
        })}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflow: "scroll",
            boxSizing: "content-box",
            paddingRight: "50px",
          }}
        >
          {children}
        </Box>
      </ModalDialog>
    </Modal>
  );
}

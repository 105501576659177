import { TabsLayout } from "@/components/layouts/TabsLayout";
import { useAppContext } from "@/modules/app/providers/AppContextProvider";
import SettingsMenuView from "@/modules/settings/menu/views/SettingsMenuView";
import { FormattedMessage } from "react-intl";

export default function SettingsMenuPage() {
  const { user } = useAppContext();

  return (
    <TabsLayout title={<FormattedMessage defaultMessage="Настройки" />}>
      <SettingsMenuView user={user} />
    </TabsLayout>
  );
}

import { CalendarMonth, Home, Settings } from "@mui/icons-material";
import { Grid } from "@mui/joy";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import Tabs from "@mui/joy/Tabs";
import React from "react";
import { useIntl } from "react-intl";
import Header from "../Header";

export enum TabChoices {
  Home,
  Schedule,
  Settings,
}

export function TabsLayoutView({
  children,
  currentTab,
  onChangeTab,
  title,
  header,
}: React.PropsWithChildren<{
  currentTab: TabChoices;
  onChangeTab: (tab: TabChoices) => void;
  title?: React.ReactNode;
  header?: React.ReactNode;
}>) {
  const intl = useIntl();

  const tabs = [
    {
      label: intl.formatMessage({ defaultMessage: "Главная" }),
      icon: <Home />,
      value: TabChoices.Home,
    },
    {
      label: intl.formatMessage({ defaultMessage: "Расписание" }),
      icon: <CalendarMonth />,
      value: TabChoices.Schedule,
    },
    {
      label: intl.formatMessage({ defaultMessage: "Настройки" }),
      icon: <Settings />,
      value: TabChoices.Settings,
    },
  ];

  return (
    <Grid container direction="column" height="100%">
      {(title || header) && <Header header={header}>{title}</Header>}
      <Grid
        container
        direction="column"
        maxWidth="100%"
        minHeight="100%"
        pt={title ? 8 : 0}
      >
        {children}
      </Grid>
      <Tabs
        size="sm"
        value={currentTab}
        onChange={(_, value) => onChangeTab(value as TabChoices)}
        sx={(theme) => ({
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "100vw",
          p: 1,
          pb: "calc(.5rem + env(safe-area-inset-bottom))",
          zIndex: theme.zIndex.badge,
          boxShadow: theme.shadow.sm,
          "--joy-shadowChannel": theme.vars.palette.primary.darkChannel,
          [`& .${tabClasses.root}`]: {
            whiteSpace: "nowrap",
            transition: "0.3s",
            fontWeight: "md",
            flex: 1,
            [`&:not(.${tabClasses.selected}):not(:hover)`]: {
              opacity: 0.72,
            },
          },
        })}
      >
        <TabList
          variant="plain"
          disableUnderline
          sx={{ borderRadius: "xl", p: 0 }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              disableIndicator
              orientation="vertical"
              value={tab.value}
              {...(currentTab === tab.value && { color: "primary" })}
            >
              <ListItemDecorator>{tab.icon}</ListItemDecorator>
              {tab.label}
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </Grid>
  );
}

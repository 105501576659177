import { MediaFile, MediaFileType } from "@/__generated__/graphql";
import { PlayCircleOutline } from "@mui/icons-material";
import { AspectRatio, Box, CardCover, Stack } from "@mui/joy";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

export const MediaSectionView = ({ media }: { media?: MediaFile[] | null }) => {
  if (!media?.length) return null;

  return (
    <Gallery options={{ zoom: false, close: true }}>
      <Box overflow="hidden">
        <Stack
          direction="row"
          gap={1}
          overflow="scroll"
          sx={{
            paddingBottom: "20px",
            marginBottom: "-20px",
            boxSizing: "content-box",
          }}
        >
          {media.map(({ url, thumbnail, width, height, type }) => (
            <Item
              key={url}
              original={url}
              thumbnail={thumbnail || url}
              width={type === MediaFileType.Video ? 1600 : width ?? undefined}
              height={type === MediaFileType.Video ? 900 : height ?? undefined}
              content={
                type === MediaFileType.Video ? (
                  <iframe
                    width="100%"
                    height="100%"
                    src={url}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                ) : undefined
              }
            >
              {({ ref, open }) => (
                <AspectRatio ratio="1" sx={{ minWidth: 120 }} objectFit="cover">
                  <img
                    ref={ref as React.MutableRefObject<HTMLImageElement>}
                    onClick={open}
                    alt="media file"
                    src={thumbnail || url}
                  />
                  {type === MediaFileType.Video && (
                    <CardCover
                      onClick={open}
                      sx={{
                        background: "rgba(0,0,0,.3)",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PlayCircleOutline
                        style={{ height: "4rem", color: "white" }}
                      />
                    </CardCover>
                  )}
                </AspectRatio>
              )}
            </Item>
          ))}
        </Stack>
      </Box>
    </Gallery>
  );
};

import { PageLayout } from "@/components/layouts/PageLayout";
import { useAppContext } from "@/modules/app";
import { useIntl } from "react-intl";
import { Navigate } from "react-router-dom";
import UserLessons from "./containers/UserLessons";

export default function MyLessonsPage() {
  const { user } = useAppContext();
  const intl = useIntl();

  if (!user) {
    return <Navigate to="/home" />;
  }

  return (
    <PageLayout title={intl.formatMessage({ defaultMessage: "Мои занятия" })}>
      <UserLessons />
    </PageLayout>
  );
}

import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Button, Grid, IconButton, Stack, Typography } from "@mui/joy";
import {
  addDays,
  addWeeks,
  differenceInCalendarDays,
  eachDayOfInterval,
  getDay,
  isSameDay,
  setDay,
} from "date-fns";
import { chunk } from "lodash";
import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import "swiper/css";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";

export default function DatePickerView({
  value: currentValue,
  onChange,
}: {
  value: Date;
  onChange: (value: Date) => void;
}) {
  const intl = useIntl();
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [slide, setSlide] = useState(0);

  const startDate = new Date();
  const endDate = addDays(startDate, 13);
  const firstDay = setDay(startDate, 1, { weekStartsOn: 1 });
  const lastDay =
    getDay(startDate) === 1 ? addDays(firstDay, 13) : addDays(firstDay, 20);

  const dates = eachDayOfInterval({
    start: firstDay,
    end: lastDay,
  });

  const items = dates.map((date) => ({
    label: `
      ${intl.formatDate(date, { day: "numeric" })}
      ${intl.formatDate(date, { weekday: "short" })}
    `,
    value: date,
    disabled:
      differenceInCalendarDays(startDate, date) > 0 ||
      differenceInCalendarDays(date, endDate) > 0,
  }));
  const chunkedItems = chunk(items, 7);
  const initialSlide = chunkedItems.findIndex((chunk) =>
    chunk.some(({ value }) => isSameDay(value, currentValue))
  );

  const swiper = useRef<SwiperRef>(null);

  return (
    <Stack gap={0}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <IconButton
          onClick={() => swiper.current?.swiper.slidePrev()}
          disabled={isStart}
        >
          <NavigateBefore />
        </IconButton>
        <Typography level="body-sm" textColor="neutral.400">
          {intl.formatDate(addWeeks(firstDay, slide), { month: "long" })}
        </Typography>
        <IconButton
          onClick={() => swiper.current?.swiper.slideNext()}
          disabled={isEnd}
        >
          <NavigateNext />
        </IconButton>
      </Stack>
      <Swiper
        ref={swiper}
        spaceBetween={0}
        slidesPerView={1}
        initialSlide={initialSlide}
        style={{ width: "100%" }}
        onFromEdge={() => [setIsStart(false), setIsEnd(false)]}
        onReachEnd={() => [setIsStart(false), setIsEnd(true)]}
        onReachBeginning={() => [setIsStart(true), setIsEnd(false)]}
        onSlideChange={(swiper: SwiperRef["swiper"]) =>
          setSlide(swiper.activeIndex)
        }
      >
        {chunkedItems.map((chunkItems, chunkIndex) => (
          <SwiperSlide key={chunkIndex}>
            <Grid
              container
              columns={8}
              justifyContent="space-between"
              sx={{ flexGrow: 1, width: "100%", px: 2 }}
            >
              {chunkItems.map(({ label, value, disabled }) => (
                <Grid key={label} xs={1}>
                  <Button
                    variant="outlined"
                    key={label}
                    disabled={disabled}
                    onClick={() => onChange(value)}
                    aria-pressed={
                      isSameDay(currentValue, value) ? "true" : "false"
                    }
                    sx={(theme) => ({
                      width: "100%",
                      [`&[aria-pressed="true"]`]: {
                        ...theme.variants.solid.primary,
                      },
                    })}
                  >
                    {label}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </SwiperSlide>
        ))}
      </Swiper>
    </Stack>
  );
}

import { SignInMode } from "@/modules/app";
import { AuthSteps } from "@/modules/auth/types";
import { useState } from "react";
import SendCode from "./SendCode";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

export default function Auth() {
  const [step, setStep] = useState(AuthSteps.SendCode);
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [userExists, setUserExists] = useState(false);
  const [signInMode, setSignInMode] = useState(SignInMode.sms);

  return (
    <>
      {step === AuthSteps.SendCode && (
        <SendCode
          phone={phone}
          setPhone={setPhone}
          setSignInMode={setSignInMode}
          onNext={(userExists: boolean = true) => {
            setUserExists(userExists);

            if (userExists) {
              setStep(AuthSteps.SignIn);
            } else {
              setStep(AuthSteps.SignUp);
            }
          }}
        />
      )}
      {step === AuthSteps.SignUp && (
        <SignUp
          name={name}
          surname={surname}
          onChangeName={setName}
          onChangeSurname={setSurname}
          onNext={() => setStep(AuthSteps.SignIn)}
          onBack={() => setStep(AuthSteps.SendCode)}
          phone={phone}
        />
      )}
      {step === AuthSteps.SignIn && (
        <SignIn
          phone={phone}
          newUser={
            !userExists && {
              name,
              surname,
            }
          }
          onNext={() => window.location.reload()}
          onBack={() => {
            if (userExists) {
              setStep(AuthSteps.SendCode);
            } else {
              setStep(AuthSteps.SignUp);
            }
          }}
          signInMode={signInMode}
        />
      )}
    </>
  );
}

import { BranchPicker } from "@/components/modals/BranchPicker/BranchPicker";
import { useFilters } from "../providers/FiltersProvider";
import BranchPickerView from "../views/BranchPickerView";

export default function BranchPickerContainer() {
  const { currentBranch, setCurrentBranch } = useFilters();

  return (
    <BranchPicker onChoose={setCurrentBranch}>
      {({ onOpen }) => (
        <BranchPickerView value={String(currentBranch.name)} onClick={onOpen} />
      )}
    </BranchPicker>
  );
}

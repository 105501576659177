import { useTeacherQuery } from "@/__generated__/graphql";
import LoadingView from "@/components/layouts/LoadingView";
import NotFoundView from "@/components/layouts/NotFoundView";
import { useAppContext } from "@/modules/app";
import { useApolloClient } from "@apollo/client";
import { uniqBy } from "lodash";
import { useParams } from "react-router-dom";
import { getCachedLinkedTecher } from "../helpers/getCachedLinkedTecher";
import TeacherView from "../views/TeacherView";

export default function TeacherContainer() {
  const { allUpcomingLessons } = useAppContext();
  const { teacherId } = useParams<{ teacherId: string }>();
  const client = useApolloClient();

  const cachedTeacher = teacherId && getCachedLinkedTecher(client, teacherId);

  const { data, loading, error } = useTeacherQuery({
    variables: {
      recId: String(teacherId),
      hasCached: Boolean(cachedTeacher),
    },
  });

  const upcomingLessons = allUpcomingLessons.filter(
    (lesson) => lesson.teacherRecId === teacherId
  );

  const activeGroups = uniqBy(
    allUpcomingLessons
      .filter((lesson) => lesson.group?.teacherRecId === teacherId)
      .map((lesson) => lesson.group)
      .filter(Boolean) as LinkedGroup[],
    "recId"
  );

  if (cachedTeacher)
    return (
      <TeacherView
        teacher={cachedTeacher}
        upcomingLessons={upcomingLessons}
        activeGroups={activeGroups}
      />
    );

  if (loading) return <LoadingView />;

  if (error || !data?.teacher) return <NotFoundView />;

  return (
    <TeacherView
      teacher={data.teacher}
      upcomingLessons={upcomingLessons}
      activeGroups={activeGroups}
    />
  );
}

import {
  Branch,
  BranchFragmentDoc,
  Group,
  GroupFragmentDoc,
  Teacher,
  TeacherFragmentDoc,
} from "@/__generated__/graphql";
import { ApolloClient } from "@apollo/client";

export const getCachedLinkedGroup = (
  client: ApolloClient<object>,
  groupId: string
) => {
  const cachedGroup = client.readFragment<Group>({
    fragmentName: "Group",
    fragment: GroupFragmentDoc,
    id: client.cache.identify({ __typename: "Group", recId: groupId }),
  });

  if (!cachedGroup) return null;

  const cachedTeacher = client.readFragment<Teacher>({
    fragmentName: "Teacher",
    fragment: TeacherFragmentDoc,
    id: client.cache.identify({
      __typename: "Teacher",
      recId: cachedGroup.teacherRecId,
    }),
  });

  const cachedBranch = client.readFragment<Branch>({
    fragmentName: "Branch",
    fragment: BranchFragmentDoc,
    id: client.cache.identify({
      __typename: "Branch",
      recId: cachedGroup?.branchRecId,
    }),
  });

  return {
    ...cachedGroup,
    teacher: cachedTeacher,
    branch: cachedBranch,
  };
};

import { Button, useTheme } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function BookLessonButtonView({
  onClick,
  loading,
}: {
  onClick: () => void;
  loading: boolean;
}) {
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      loading={loading}
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: theme.zIndex.badge,
        borderRadius: 0,
        padding: 2,
      }}
      size="md"
    >
      <FormattedMessage defaultMessage="Записаться" />
    </Button>
  );
}

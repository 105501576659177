import { Teacher, TeacherFragmentDoc } from "@/__generated__/graphql";
import { ApolloClient } from "@apollo/client";

export const getCachedLinkedTecher = (
  client: ApolloClient<object>,
  teacherId: string
) => {
  return client.readFragment<Teacher>({
    fragmentName: "Teacher",
    fragment: TeacherFragmentDoc,
    id: client.cache.identify({ __typename: "Teacher", recId: teacherId }),
  });
};

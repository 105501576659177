import { PassStatus } from "@/__generated__/graphql";
import { useAppContext } from "@/modules/app";
import { partition } from "lodash";
import UserPassesView from "../views/UserPassesView";

export default function UserPasses() {
  const { allPasses } = useAppContext();

  const [activePasses, deactivatedPasses] = partition(
    allPasses,
    (pass) => pass.status === PassStatus.Active
  );

  return (
    <UserPassesView
      activePasses={activePasses}
      deactivatedPasses={deactivatedPasses}
    />
  );
}

import en from "@/__generated__/lang/en.json";
import ru from "@/__generated__/lang/ru.json";
import env from "@/env";
import React from "react";
import { IntlProvider } from "react-intl";

const messages: {
  ru: typeof ru;
  en: typeof ru;
} = {
  en,
  ru,
};

export const AppIntlProvider = ({ children }: React.PropsWithChildren) => {
  const locale = env.REACT_APP_LOCALE;

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}
      onError={console.error}
      defaultLocale={locale}
    >
      {children}
    </IntlProvider>
  );
};

import { useCancelLessonMutation } from "@/__generated__/graphql";
import { AppModal } from "@/components/modals/AppModal/AppModal";
import { extractGraphQLErrorMessage } from "@/helpers";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isLessonCancellationFree } from "../helpers/isLessonCancellationFree";
import CancelLessonButtonView from "../views/cancellation/CancelLessonButtonView";
import CancelLessonForFreeView from "../views/cancellation/CancelLessonForFreeView";
import CancelLessonSuccessView from "../views/cancellation/CancelLessonSuccessView";
import CancelLessonWithPenaltyView from "../views/cancellation/CancelLessonWithPenaltyView";

export default function CancelLesson({ lesson }: { lesson: LinkedLesson }) {
  const [openFreeCancellation, setOpenFreeCancellation] = useState(false);
  const [openPaidCancellation, setOpenPaidCancellation] = useState(false);
  const [openCancellationSuccess, setOpenCancellationSuccess] = useState(false);
  const navigate = useNavigate();

  const [cancelLesson, { loading }] = useCancelLessonMutation({
    variables: {
      lessonRecId: lesson.recId,
      passRecId: String(lesson.passRecId),
    },
  });

  const onClick = () => {
    if (isLessonCancellationFree(lesson)) {
      setOpenFreeCancellation(true);
    } else {
      setOpenPaidCancellation(true);
    }
  };

  const onCancelClick = () => {
    setOpenFreeCancellation(false);
    setOpenPaidCancellation(false);

    cancelLesson()
      .then(() => {
        setOpenCancellationSuccess(true);
      })
      .catch((error) => {
        toast.error(extractGraphQLErrorMessage(error));
      });
  };

  const onBookClick = () => {
    setOpenCancellationSuccess(false);
    navigate("/");
  };

  return (
    <>
      {lesson.passRecId && (
        <CancelLessonButtonView onClick={onClick} loading={loading} />
      )}
      <AppModal
        open={openFreeCancellation}
        onClose={() => setOpenFreeCancellation(false)}
      >
        <CancelLessonForFreeView
          onClose={() => setOpenFreeCancellation(false)}
          onSubmit={onCancelClick}
          lesson={lesson}
        />
      </AppModal>
      <AppModal
        open={openPaidCancellation}
        onClose={() => setOpenPaidCancellation(false)}
      >
        <CancelLessonWithPenaltyView
          onClose={() => setOpenPaidCancellation(false)}
          onSubmit={onCancelClick}
          lesson={lesson}
        />
      </AppModal>
      <AppModal
        open={openCancellationSuccess}
        onClose={() => setOpenCancellationSuccess(false)}
      >
        <CancelLessonSuccessView
          lesson={lesson}
          onBookClick={onBookClick}
          onClose={() => setOpenCancellationSuccess(false)}
        />
      </AppModal>
    </>
  );
}

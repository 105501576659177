import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import React from "react";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import "./index.css";
import { router } from "./modules/app/router";
import "./sentry";

const root = ReactDOM.createRoot((document as any).getElementById("root"));

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

if (process.env.NODE_ENV === "development") {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

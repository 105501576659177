import { PageLayout } from "@/components/layouts/PageLayout";
import { useIntl } from "react-intl";
import TeacherContainer from "./containers/Teacher";

export default function TeacherPage() {
  const intl = useIntl();

  return (
    <PageLayout title={intl.formatMessage({ defaultMessage: "Преподаватель" })}>
      <TeacherContainer />
    </PageLayout>
  );
}

import * as Sentry from "@sentry/react";
import env from "./env";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://7034c4c8ca4d587855f2e7d58f0a671d@o4506014190469120.ingest.sentry.io/4506014194401280",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    environment: env.REACT_APP_ENV,
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  });
}

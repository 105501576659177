import { Tab, TabList, Tabs, tabClasses } from "@mui/joy";
import React from "react";

export default function PageTabs({
  children,
  tabs,
}: React.PropsWithChildren<{
  tabs: React.ReactNode[];
}>) {
  return (
    <Tabs defaultValue={0} aria-label="tabs" sx={{ bgcolor: "transparent" }}>
      <TabList
        disableUnderline
        sx={{
          p: 0.5,
          gap: 0.5,
          borderRadius: "xl",
          bgcolor: "background.level1",
          [`& .${tabClasses.root}[aria-selected="true"]`]: {
            boxShadow: "sm",
            bgcolor: "background.surface",
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab key={index} disableIndicator sx={{ width: "50%" }}>
            {tab}
          </Tab>
        ))}
      </TabList>
      {children}
    </Tabs>
  );
}

import { InitialDataDocument, InitialDataQuery } from "@/__generated__/graphql";
import env from "@/env";
import {
  ApolloClient,
  ApolloProvider,
  FieldFunctionOptions,
  FieldPolicy,
  InMemoryCache,
  defaultDataIdFromObject,
} from "@apollo/client";
import { filter, find } from "lodash";
import React from "react";
import { getAuthToken } from "./helpers/auth";

const dateTimeReader: FieldPolicy = {
  read(date: any) {
    return typeof date === "string" ? new Date(date.replace("Z", "")) : date;
  },
};

const dateReader: FieldPolicy = {
  read(date: any) {
    return typeof date === "string" ? new Date(date) : date;
  },
};

function createInitDataReader<T>(
  mapFn: (
    data: InitialDataQuery | null,
    { readField }: FieldFunctionOptions
  ) => T
): FieldPolicy {
  return {
    read(_, options) {
      const data = options.cache.readQuery<InitialDataQuery>({
        query: InitialDataDocument,
        variables: {
          locationId: env.REACT_APP_LOCATION_ID,
          locationRecId: env.REACT_APP_LOCATION_REC_ID,
        },
      });

      return mapFn(data, options);
    },
  };
}

const client = new ApolloClient({
  uri: env.REACT_APP_API_URL,
  ...(getAuthToken() && {
    headers: {
      Authorization: getAuthToken() as string,
    },
  }),
  cache: new InMemoryCache({
    dataIdFromObject(responseObject) {
      if (responseObject.recId) return String(responseObject.recId);

      return defaultDataIdFromObject(responseObject);
    },
    typePolicies: {
      Group: {
        fields: {
          branch: createInitDataReader((data, { readField }) =>
            find(data?.branches.items, {
              recId: readField("branchRecId"),
            })
          ),
          teacher: createInitDataReader((data, { readField }) =>
            find(data?.allUpcomingLessons.teachers, {
              recId: readField("teacherRecId"),
            })
          ),
          upcomingLessons: createInitDataReader((data, { readField }) =>
            filter(data?.allUpcomingLessons.lessons, {
              groupRecId: readField("recId"),
            })
          ),
        },
      },
      Teacher: {
        fields: {
          branch: createInitDataReader((data, { readField }) =>
            find(data?.branches.items, {
              recId: readField("branchRecId"),
            })
          ),
          activeGroups: createInitDataReader((data, { readField }) =>
            find(data?.allUpcomingLessons.groups, {
              teacherRecId: readField("recId"),
              archived: false,
            })
          ),
          upcomingLessons: createInitDataReader((data, { readField }) =>
            filter(data?.allUpcomingLessons.lessons, {
              teacherRecId: readField("recId"),
              passed: false,
            })
          ),
        },
      },
      Lesson: {
        fields: {
          startDateTime: dateTimeReader,
          endDateTime: dateTimeReader,
        },
      },
      Pass: {
        fields: {
          activationDate: dateReader,
          deactivationDate: dateReader,
          upcomingLessons: createInitDataReader((data, { readField }) => {
            const recId = readField("recId");

            return (
              typeof recId === "string" &&
              filter(
                data?.allUpcomingLessons.lessons,
                (lesson) =>
                  !lesson.passed && lesson.bookingsRecIds.includes(recId)
              )
            );
          }),
        },
      },
      PassFreeze: {
        fields: {
          startDate: dateReader,
        },
      },
      PassRefund: {
        fields: {
          date: dateReader,
        },
      },
      PassSickList: {
        fields: {
          startDate: dateReader,
          endDate: dateReader,
        },
      },
      UserProfile: {
        fields: {
          birthday: dateReader,
        },
      },
    },
  }),
});

export const AppApolloProvider = ({ children }: React.PropsWithChildren) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

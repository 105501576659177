import { Box } from "@mui/joy";
import CssBaseline from "@mui/joy/CssBaseline";
import { CssVarsProvider } from "@mui/joy/styles";
import { Outlet, ScrollRestoration } from "react-router-dom";
import ErrorView from "../../../components/layouts/ErrorView";
import LoadingView from "../../../components/layouts/LoadingView";
import { AppApolloProvider } from "../providers/AppApolloProvider";
import { AppContextProvider } from "../providers/AppContextProvider";
import { AppIntlProvider } from "../providers/AppIntlProvider";
import ToastProvider from "../providers/ToastProvider";

export function App() {
  return (
    <CssVarsProvider>
      <CssBaseline />
      <ScrollRestoration />
      <AppIntlProvider>
        <AppApolloProvider>
          <AppContextProvider>
            {({ loading, error }) => {
              if (loading || error)
                return (
                  <Box height="100%">
                    {loading ? <LoadingView /> : <ErrorView />}
                  </Box>
                );

              return <Outlet />;
            }}
          </AppContextProvider>
          <ToastProvider />
        </AppApolloProvider>
      </AppIntlProvider>
    </CssVarsProvider>
  );
}

import { some } from "lodash";

const env = {
  REACT_APP_LESSON_CANCELLATION_MIN_HOURS: Number(
    process.env.REACT_APP_LESSON_CANCELLATION_MIN_HOURS
  ),
  REACT_APP_LOCATION_REC_ID: String(process.env.REACT_APP_LOCATION_REC_ID),
  REACT_APP_LOCATION_ID: String(process.env.REACT_APP_LOCATION_ID),
  REACT_APP_LOCALE: String(process.env.REACT_APP_LOCALE) as "ru" | "en",
  REACT_APP_API_URL: String(process.env.REACT_APP_API_URL),
  REACT_APP_PRIVACY_POLICY_URL: String(
    process.env.REACT_APP_PRIVACY_POLICY_URL
  ),
  REACT_APP_TERMSOF_USE_URL: String(process.env.REACT_APP_TERMSOF_USE_URL),
  REACT_APP_PHONE_COUNTRY_SYMBOL: String(
    process.env.REACT_APP_PHONE_COUNTRY_SYMBOL
  ) as "ae" | "by",
  REACT_APP_ENV: String(process.env.REACT_APP_ENV),
};

if (some(env, (value) => !value)) {
  throw new Error("Missing env variables");
}

if (!["ru", "en"].includes(env.REACT_APP_LOCALE)) {
  throw new Error("Invalid REACT_APP_LOCALE");
}

if (!["ae", "by"].includes(env.REACT_APP_PHONE_COUNTRY_SYMBOL)) {
  throw new Error("Invalid REACT_APP_PHONE_COUNTRY_SYMBOL");
}

export default env;

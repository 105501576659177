import { Teacher } from "@/__generated__/graphql";
import { KeyboardArrowRight } from "@mui/icons-material";
import {
  Avatar,
  Box,
  CardContent,
  ListItem,
  ListItemButton,
  Typography,
  useTheme,
} from "@mui/joy";
import { Link as RouterLink } from "react-router-dom";

export function TeacherSectionView({
  teacher,
  replacedTeacher,
}: {
  teacher?: Teacher | null;
  replacedTeacher?: Teacher | null | false;
}) {
  const theme = useTheme();

  if (!teacher?.appName) return null;

  return (
    <ListItem>
      <ListItemButton
        component={RouterLink}
        to={`/teachers/${teacher.recId}`}
        sx={{
          "--joy-palette-neutral-plainHoverBg": "#fff",
          "--joy-palette-neutral-plainActiveBg": "#fff",
        }}
      >
        <CardContent orientation="horizontal">
          {teacher.avatar && (
            <Avatar
              size="lg"
              sx={{ mr: 2 }}
              src={teacher.avatar.thumbnail || teacher.avatar.url}
            />
          )}
          <CardContent sx={{ alignItems: "center", flexDirection: "row" }}>
            <Box>
              <Typography level="title-md">
                <b>
                  {replacedTeacher ? (
                    <>
                      <s>{replacedTeacher.appName}</s>
                      {" → "}
                      {teacher.appName}
                    </>
                  ) : (
                    teacher.appName
                  )}
                </b>
              </Typography>
              {teacher.appTitle && (
                <Typography level="body-sm" textColor="neutral.400">
                  {teacher.appTitle}
                </Typography>
              )}
            </Box>
          </CardContent>
        </CardContent>
        <KeyboardArrowRight sx={{ fontSize: theme.fontSize.xl3 }} />
      </ListItemButton>
    </ListItem>
  );
}

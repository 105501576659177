import { Box, Typography } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export function EmptyListView() {
  return (
    <Box
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Typography color="neutral">
        <FormattedMessage defaultMessage={"Ничего не найдено"} />
      </Typography>
    </Box>
  );
}

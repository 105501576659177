import {
  useSendVerificationCodeMutation,
  useUserExistsLazyQuery,
} from "@/__generated__/graphql";
import env from "@/env";
import { extractGraphQLErrorMessage } from "@/helpers";
import { SignInMode } from "@/modules/app";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import SendCodeView from "../views/SendCodeView";

export default function SendCode({
  phone,
  setPhone,
  onNext,
  setSignInMode,
}: {
  phone: string;
  setPhone: (phone: string) => void;
  onNext: (userExists: boolean) => void;
  setSignInMode: (signInMode: SignInMode) => void;
}) {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
  const signInAs = params.has("signInAs");

  const [fetchUserExists] = useUserExistsLazyQuery();
  const [sendCode] = useSendVerificationCodeMutation();

  const onSubmit = async () => {
    setLoading(true);
    const parsedPhone = `+${phone.replace(/\D/g, "")}`;

    try {
      const { data } = await fetchUserExists({
        variables: {
          phone: parsedPhone,
          locationId: env.REACT_APP_LOCATION_ID,
        },
      });

      if (
        data &&
        !data.userExists.exists &&
        data.userExists.selfSignUpAllowed
      ) {
        return onNext(false);
      }

      if (data && !data.userExists.selfSignUpAllowed)
        setSignInMode(SignInMode.identifier);

      if (signInAs) setSignInMode(SignInMode.signInAs);

      await sendCode({
        variables: {
          phone: parsedPhone,
          locationId: env.REACT_APP_LOCATION_ID,
          signInAs,
        },
      });

      onNext(true);
    } catch (err) {
      return setError(extractGraphQLErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  const onChangePhone = (value: string) => {
    setPhone(value);
    setError(null);
  };

  return (
    <SendCodeView
      onChange={onChangePhone}
      onSubmit={onSubmit}
      loading={loading}
      value={phone}
      error={error}
      signInAs={signInAs}
    />
  );
}

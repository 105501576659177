import React from "react";
import { useNavigate } from "react-router-dom";
import { PageLayoutView } from "./PageLayoutView";

export function PageLayout({
  children,
  title,
}: React.PropsWithChildren<{
  title: string;
}>) {
  const navigate = useNavigate();
  const onBack = () => {
    navigate(-1);
  };

  return (
    <PageLayoutView title={title} onBack={onBack}>
      {children}
    </PageLayoutView>
  );
}

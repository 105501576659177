import NotFoundPage from "@/components/layouts/NotFoundPage";
import { TabChoices } from "@/components/layouts/TabsLayout";
import MyLessonsPage from "@/modules/lessons/myLessons";
import SchedulePage from "@/modules/lessons/schedule";
import { createBrowserRouter } from "react-router-dom";
import GroupPage from "../group";
import HomePage from "../home";
import LessonPage from "../lessons/lesson";
import MyPassesPage from "../passes/myPasses";
import PassPage from "../passes/pass";
import ProfileEditPage from "../profile";
import SettingsMenuPage from "../settings/menu";
import SettingsRulesPage from "../settings/rules";
import TeacherPage from "../teacher";
import { App } from "./containers/App";
import { AppIntlProvider } from "./providers/AppIntlProvider";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <SchedulePage />,
        handle: {
          tab: TabChoices.Schedule,
        },
      },
      {
        path: "/home",
        element: <HomePage />,
        handle: {
          tab: TabChoices.Home,
        },
      },
      {
        path: "/settings",
        element: <SettingsMenuPage />,
        handle: {
          tab: TabChoices.Settings,
        },
      },
      {
        path: "/settings/rules",
        element: <SettingsRulesPage />,
      },
      {
        path: "/profile/edit",
        element: <ProfileEditPage />,
      },
      {
        path: "/profile/lessons",
        element: <MyLessonsPage />,
      },
      {
        path: "/profile/passes",
        element: <MyPassesPage />,
      },
      {
        path: "/passes/:passId",
        element: <PassPage />,
      },
      {
        path: "/lessons/:lessonId",
        element: <LessonPage />,
      },
      {
        path: "/groups/:groupId",
        element: <GroupPage />,
      },
      {
        path: "/teachers/:teacherId",
        element: <TeacherPage />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <AppIntlProvider>
        <NotFoundPage />
      </AppIntlProvider>
    ),
  },
]);

import { Box } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function NotFoundView() {
  return (
    <Box
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <FormattedMessage defaultMessage="Ничего не найдено" />
    </Box>
  );
}

import { Avatar, Box, CardContent, Typography } from "@mui/joy";

export default function TeacherContent({
  teacher,
  replacedTeacher,
}: {
  teacher?: Teacher | null;
  replacedTeacher?: Teacher | null | false;
}) {
  if (!teacher || !teacher.appName) return null;

  return (
    <CardContent orientation="horizontal">
      {teacher.avatar && (
        <Avatar src={teacher.avatar.thumbnail || teacher.avatar.url} />
      )}
      <CardContent sx={{ alignItems: "center", flexDirection: "row" }}>
        <Box>
          <Typography level="title-sm">
            <b>
              {replacedTeacher ? (
                <>
                  <s>{replacedTeacher.appName}</s>
                  {" → "}
                  {teacher.appName}
                </>
              ) : (
                teacher.appName
              )}
            </b>
          </Typography>
          {teacher.appTitle && (
            <Typography level="body-xs" textColor="neutral.400">
              {teacher.appTitle}
            </Typography>
          )}
        </Box>
      </CardContent>
    </CardContent>
  );
}

import { Button, FormControl, FormLabel, Input } from "@mui/joy";
import { FormattedMessage } from "react-intl";
import AuthFormView from "./AuthFormView";

export default function SignUpView({
  onChangeName,
  onChangeSurname,
  onSubmit,
  name,
  surname,
  onBack,
}: {
  onChangeName: (name: string) => void;
  onChangeSurname: (surname: string) => void;
  onSubmit: () => void;
  name: string;
  surname: string;
  onBack: () => void;
}) {
  return (
    <AuthFormView onBack={onBack}>
      <FormControl>
        <FormLabel>
          <FormattedMessage defaultMessage="Введите имя" />
        </FormLabel>
        <Input
          autoComplete="given-name"
          name="name"
          value={name}
          onChange={(e) => onChangeName(e.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>
          <FormattedMessage defaultMessage="Введите фамилию" />
        </FormLabel>
        <Input
          autoComplete="given-name"
          name="surname"
          value={surname}
          onChange={(e) => onChangeSurname(e.target.value)}
        />
      </FormControl>

      <Button
        type="submit"
        onClick={onSubmit}
        disabled={name.length === 0 || surname.length === 0}
      >
        <FormattedMessage defaultMessage="Зарегистрироваться" />
      </Button>
    </AuthFormView>
  );
}

import { UserProfile } from "@/__generated__/graphql";
import { BranchPicker } from "@/components/modals/BranchPicker/BranchPicker";
import { KeyboardArrowRight, Person } from "@mui/icons-material";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  useTheme,
} from "@mui/joy";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const SettingsMenuItem = ({
  title,
  icon,
  href,
  onClick,
}: {
  title: React.ReactNode;
  icon?: React.ReactNode;
} & (
  | {
      href: string;
      onClick?: undefined;
    }
  | {
      onClick: () => void;
      href?: undefined;
    }
)) => {
  const theme = useTheme();

  return (
    <ListItem>
      <ListItemButton
        {...(onClick ? { onClick } : { component: Link, to: href })}
        sx={{ "--IconButton-size": "60px" }}
      >
        {icon}
        <ListItemContent>{title}</ListItemContent>
        <KeyboardArrowRight sx={{ fontSize: theme.fontSize.xl3 }} />
      </ListItemButton>
    </ListItem>
  );
};

export default function SettingsMenuView({
  user,
}: {
  user?: UserProfile | null;
}) {
  const theme = useTheme();

  const authMenu = user ? (
    <>
      <SettingsMenuItem
        title={[user.name, user.surname].filter(Boolean).join(" ")}
        icon={<Person sx={{ pr: 2, fontSize: theme.fontSize.xl4 }} />}
        href="/profile/edit"
      />
      <SettingsMenuItem
        title={<FormattedMessage defaultMessage="Мои занятия" />}
        href="/profile/lessons"
      />
      <SettingsMenuItem
        title={<FormattedMessage defaultMessage="Мои абонементы" />}
        href="/profile/passes"
      />
    </>
  ) : (
    <SettingsMenuItem
      title={<FormattedMessage defaultMessage="Войти в аккаунт" />}
      href="/home"
    />
  );

  return (
    <List size="lg">
      {authMenu}
      <SettingsMenuItem
        title={<FormattedMessage defaultMessage="Правила школы" />}
        href="/settings/rules"
      />

      <BranchPicker>
        {({ onOpen }) => (
          <SettingsMenuItem
            title={<FormattedMessage defaultMessage="Связаться с нами" />}
            onClick={onOpen}
          />
        )}
      </BranchPicker>
    </List>
  );
}

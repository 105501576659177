import { Branch, Teacher } from "@/__generated__/graphql";
import { getTimeSlot } from "@/modules/lessons/schedule/providers/helpers/getTimeSlot";
import { omit, reverse, sortBy, uniqBy } from "lodash";
import { IntlShape } from "react-intl";
import { Filters, FiltersOptions } from "../../types";
import { filterLessons } from "./filterLessons";

const getGroupType = (l: LinkedLesson): Filters["groupTypes"][0] => ({
  id: `${l.group?.direction}-${l.group?.level}`,
  level: l.group?.level,
  direction: l.group?.direction,
});

export const getFiltersOptionsFn =
  (
    intl: IntlShape,
    allBranches: Branch[],
    allUpcomingLessons: LinkedLesson[]
  ) =>
  (currentBranch: Branch, newFilters: Filters): FiltersOptions => {
    const getLessons = (key: keyof Filters) =>
      filterLessons(allUpcomingLessons, currentBranch, omit(newFilters, key));

    const teachersLessons = getLessons("teachers");
    const groupTypesLessons = getLessons("groupTypes");
    const timeSlotsLessons = getLessons("timeSlots");

    const teachers = uniqBy(
      [
        ...teachersLessons.map((l) => l.teacher),
        ...teachersLessons.map((l) => l.group?.teacher),
      ],
      "recId"
    ).filter(Boolean) as Teacher[];

    return {
      branches: allBranches.map((branch) => ({
        value: branch,
        selected: branch.recId === currentBranch.recId,
      })),

      teachers: sortBy(
        teachers.map((teacher) => ({
          value: teacher,
          selected: newFilters.teachers.some((t) => t.recId === teacher?.recId),
        })),
        "value.id"
      ),

      groupTypes: reverse(
        sortBy(
          uniqBy(groupTypesLessons.map(getGroupType), "id").map(
            (groupType) => ({
              value: groupType,
              selected: newFilters.groupTypes.some(
                (gt) => gt.id === groupType.id
              ),
            })
          ),
          "value.id"
        )
      ),

      timeSlots: sortBy(
        uniqBy(timeSlotsLessons.map(getTimeSlot(intl)), "label").map(
          (time) => ({
            value: time,
            selected: newFilters.timeSlots.some((t) => t.label === time.label),
          })
        ),
        "value.from"
      ),
    };
  };

import { Box, Grid } from "@mui/joy";
import React from "react";
import Header from "../Header";

export function PageLayoutView({
  children,
  title,
  onBack,
}: {
  title: string;
  children: React.ReactNode;
  onBack: () => void;
}) {
  return (
    <Grid container direction="row" height="100%">
      <Header onBack={onBack}>{title}</Header>
      <Box px={3} py={8} width="100%">
        {children}
      </Box>
    </Grid>
  );
}

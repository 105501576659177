import { Box, Button } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function NotFoundPage() {
  return (
    <Box
      display="flex"
      height="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={2}
    >
      <FormattedMessage defaultMessage="Страница не найдена" />
      <Button component="a" href="/" variant="outlined" color="neutral">
        <FormattedMessage defaultMessage="На главную" />
      </Button>
    </Box>
  );
}

import { UserProfile } from "@/__generated__/graphql";
import { formatPhone } from "@/helpers";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function ProfileEditView({
  user,
  onSubmit,
  onLogout,
  loading,
  birthday,
  onChangeBirthday,
}: {
  user: UserProfile;
  onSubmit: () => void;
  onLogout: () => void;
  loading: boolean;
  birthday: string;
  onChangeBirthday: (value: string) => void;
}) {
  return (
    <Stack gap={2}>
      <FormControl>
        <FormLabel>
          <FormattedMessage defaultMessage="Имя" />
        </FormLabel>
        <Typography textColor="neutral.500">{user.name}</Typography>
      </FormControl>
      <FormControl>
        <FormLabel>
          <FormattedMessage defaultMessage="Фамилия" />
        </FormLabel>
        <Typography textColor="neutral.500">{user.surname}</Typography>
      </FormControl>
      <FormControl>
        <FormLabel>
          <FormattedMessage defaultMessage="Телефон" />
        </FormLabel>
        <Typography textColor="neutral.500">
          {formatPhone(user.phone)}
        </Typography>
      </FormControl>
      <FormControl>
        <FormLabel>
          <FormattedMessage defaultMessage="День рождения" />
        </FormLabel>
        <Input
          type="date"
          value={birthday}
          onChange={(e) => onChangeBirthday(e.target.value)}
        />
      </FormControl>

      <Button type="submit" onClick={onSubmit} loading={loading}>
        <FormattedMessage defaultMessage="Сохранить изменения" />
      </Button>

      <Button variant="outlined" onClick={onLogout}>
        <FormattedMessage defaultMessage="Выйти из аккаунта" />
      </Button>
    </Stack>
  );
}

import { AppModal } from "@/components/modals/AppModal/AppModal";
import { useState } from "react";
import { useFilters } from "../providers/FiltersProvider";
import FiltersButtonView from "../views/FiltersButtonView";
import ScheduleFiltersView from "../views/ScheduleFiltersView";

export default function Filters() {
  const [open, setOpen] = useState(false);
  const {
    filters,
    filtersOptions,
    setNextFilters,
    applyNextFilters,
    resetFilters,
  } = useFilters();

  const active =
    filters.teachers.length +
      filters.groupTypes.length +
      filters.timeSlots.length >
    0;

  return (
    <>
      <FiltersButtonView onClick={() => setOpen(true)} active={active} />
      <AppModal open={open} onClose={() => setOpen(false)}>
        <ScheduleFiltersView
          options={filtersOptions}
          onChange={setNextFilters}
          onReset={resetFilters}
          onSubmit={() => [applyNextFilters(), setOpen(false)]}
        />
      </AppModal>
    </>
  );
}

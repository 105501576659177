import { UserProfile, useUpdateProfileMutation } from "@/__generated__/graphql";
import { extractGraphQLErrorMessage } from "@/helpers";
import { useAppContext } from "@/modules/app/providers/AppContextProvider";
import { formatISO } from "date-fns";
import { useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import ProfileEditView from "../views/ProfileEditView";

const formatDate = (date?: Date): string =>
  date ? formatISO(date, { representation: "date" }) : "";

export default function ProfileEdit({ user }: { user: UserProfile }) {
  const { removeAuthToken } = useAppContext();
  const [birthday, setBirthday] = useState(formatDate(user?.birthday));
  const [updateProfile, { loading }] = useUpdateProfileMutation();
  const intl = useIntl();

  const onLogout = () => {
    removeAuthToken();
    window.location.reload();
  };

  const onSubmit = async () => {
    try {
      await updateProfile({
        variables: {
          birthday: birthday ? formatDate(new Date(birthday)) : null,
        },
      });

      toast.success(
        intl.formatMessage({
          defaultMessage: "Профиль успешно обновлен",
        })
      );
    } catch (error) {
      toast.error(extractGraphQLErrorMessage(error));
    }
  };

  return (
    <ProfileEditView
      user={user}
      onLogout={onLogout}
      onSubmit={onSubmit}
      onChangeBirthday={setBirthday}
      birthday={birthday}
      loading={loading}
    />
  );
}

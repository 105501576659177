import { Branch } from "@/__generated__/graphql";
import { useAppContext } from "@/modules/app/providers/AppContextProvider";
import React, { useState } from "react";
import { AppModal } from "../AppModal/AppModal";
import { BranchView } from "./views/BranchView";
import { BranchesListView } from "./views/BranchesListView";

export const BranchPicker = ({
  onChoose,
  children,
}: {
  onChoose?: (branch: Branch) => void;
  children: React.FunctionComponent<{ onOpen: () => void }>;
}) => {
  const { allBranches } = useAppContext();
  const [open, setOpen] = useState(false);
  const [displayedBranch, showBranch] = useState<Branch | null>(null);

  const onChooseBranch =
    onChoose &&
    ((branch: Branch) => {
      onChoose?.(branch);
      setOpen(false);
      showBranch(null);
    });

  const onClose = () => {
    setOpen(false);
    showBranch(null);
  };

  return (
    <>
      {children({ onOpen: () => setOpen(true) })}
      <AppModal open={open} onClose={onClose}>
        {displayedBranch ? (
          <BranchView
            branch={displayedBranch}
            onChoose={
              onChooseBranch ? () => onChooseBranch(displayedBranch) : undefined
            }
            onBack={() => showBranch(null)}
          />
        ) : (
          <BranchesListView
            branches={allBranches}
            onSubmit={(branch) => showBranch(branch)}
          />
        )}
      </AppModal>
    </>
  );
};

import { Branch } from "@/__generated__/graphql";
import { last, sortBy } from "lodash";

const getLastPass = (allPasses: PartialPass[]): PartialPass | undefined => {
  if (allPasses.length === 0) return;

  const sorted = sortBy(
    allPasses.filter((pass) => pass.activationDate),
    (pass) => pass.activationDate
  );

  if (sorted.length > 0) return last(sorted);

  return allPasses[0];
};

export const getDefaultBranch = (
  allBranches: Branch[],
  allPasses?: PartialPass[] | null
) => {
  const lastPass = allPasses && getLastPass(allPasses);

  return (
    (lastPass &&
      allBranches.find((branch) => branch.recId === lastPass.branchRecId)) ??
    allBranches[0]
  );
};

import env from "@/env";
import { ApolloError } from "@apollo/client/errors";
import { map } from "lodash";

export const MASK = {
  by: [/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3-$4-$5"] as [
    RegExp,
    string,
  ],
  ae: [/(\d{3})(\d{2})(\d{3})(\d{4})/, "+$1 $2 $3 $4"] as [RegExp, string],
}[env.REACT_APP_PHONE_COUNTRY_SYMBOL];

const defaultMessage = {
  ru: "Неизвестная ошибка",
  en: "Unknown error",
}[env.REACT_APP_LOCALE];

export const extractGraphQLErrorMessage = (
  error?: ApolloError | any
): string => {
  const localizedError = map(
    error?.graphQLErrors,
    `extensions.i18n.${env.REACT_APP_LOCALE}`
  ).find(Boolean);

  return localizedError || defaultMessage;
};

export const formatPhone = (phone?: number | string | null) =>
  phone && String(phone).replace(/[^\d]/g, "").replace(MASK[0], MASK[1]);

import PageTabs from "@/components/layouts/PageTabs";
import { LessonsListView } from "@/components/lists/LessonsListView/LessonsListView";
import { Alert, Box, CircularProgress, TabPanel, Typography } from "@mui/joy";
import { FormattedMessage } from "react-intl";
import { MAX_PAST_LESSONS } from "../constants";

export default function UserLessonsView({
  upcomingLessons,
  pastLessons,
}: {
  upcomingLessons: {
    data: LinkedLesson[];
  };
  pastLessons: {
    data?: LinkedLesson[];
    loading: boolean;
    error?: string;
  };
}) {
  return (
    <PageTabs
      tabs={[
        <FormattedMessage defaultMessage="Ближайшие" />,
        <FormattedMessage defaultMessage="Прошедшие" />,
      ]}
    >
      <TabPanel value={0} sx={{ px: 0 }}>
        <LessonsListView lessons={upcomingLessons.data} />
      </TabPanel>
      <TabPanel value={1} sx={{ px: 0 }}>
        {pastLessons.loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ py: 8 }}
          >
            <CircularProgress />
          </Box>
        ) : pastLessons.error || !pastLessons.data ? (
          <Alert color="danger">{pastLessons.error}</Alert>
        ) : (
          <>
            <LessonsListView lessons={pastLessons.data} />
            <Typography
              textColor="neutral.300"
              level="body-sm"
              sx={{ textAlign: "center", mt: 2 }}
            >
              <FormattedMessage
                defaultMessage="Отображены последние {count} занятий"
                values={{
                  count: MAX_PAST_LESSONS,
                }}
              />
            </Typography>
          </>
        )}
      </TabPanel>
    </PageTabs>
  );
}

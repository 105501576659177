import { Box } from "@mui/joy";
import React from "react";
import Markdown from "react-markdown";

export default function DescriptionSectionView({
  label,
  content,
}: {
  label?: React.ReactNode;
  content?: string | null;
}) {
  return Boolean(content?.trim()) ? (
    <Box className="markdown">
      {label}
      <Markdown>{content}</Markdown>
    </Box>
  ) : null;
}

import {
  Branch,
  Location,
  PassLessonsType,
  useAuthDataQuery,
  useInitialDataQuery,
  UserProfile,
} from "@/__generated__/graphql";
import env from "@/env";
import { mapLessons } from "@/modules/app/providers/helpers/lessons";
import React, { createContext, useContext } from "react";
import { removeAuthToken, setAuthToken } from "./helpers/auth";

const AppContext = createContext<AppContextProps>(null as any);

export interface AppContextProps {
  allUpcomingLessons: LinkedLesson[];
  allBranches: Branch[];
  allPasses?: PartialPass[] | null;
  user?: UserProfile | null;
  setAuthToken: (token: string) => void;
  removeAuthToken: () => void;
  location: Location;
}

export const AppContextProvider = ({
  children,
}: {
  children: React.FunctionComponent<{
    loading?: boolean;
    error?: boolean;
  }>;
}) => {
  const initialDataQuery = useInitialDataQuery({
    variables: {
      locationId: env.REACT_APP_LOCATION_ID,
      locationRecId: env.REACT_APP_LOCATION_REC_ID,
    },
  });

  const authDataQuery = useAuthDataQuery();

  if (authDataQuery.loading || initialDataQuery.loading) {
    return children({ loading: true });
  }

  if (!initialDataQuery.data || initialDataQuery.error) {
    console.log(initialDataQuery.error);
    return children({ error: true });
  }

  const allPasses = authDataQuery.data?.allPasses.items.filter(
    (pass) => pass.lessonsType === PassLessonsType.Group
  );

  const allUpcomingLessons = mapLessons(
    initialDataQuery.data.branches.items,
    initialDataQuery.data.allUpcomingLessons.teachers,
    initialDataQuery.data.allUpcomingLessons.groups,
    initialDataQuery.data.allUpcomingLessons.lessons,
    allPasses
  );

  return (
    <AppContext.Provider
      value={{
        allUpcomingLessons,
        allBranches: initialDataQuery.data.branches.items,
        location: initialDataQuery.data.location,
        user: authDataQuery.data?.profile,
        allPasses,
        setAuthToken,
        removeAuthToken,
      }}
    >
      {children({})}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

import { TabsLayout } from "@/components/layouts/TabsLayout";
import { AppModal } from "@/components/modals/AppModal/AppModal";
import { useAppContext } from "@/modules/app/providers/AppContextProvider";
import { Button, Stack, Typography } from "@mui/joy";
import { noop } from "lodash";
import { FormattedMessage } from "react-intl";
import { FiltersProvider } from "../providers/FiltersProvider";
import BranchPickerContainer from "./BranchPicker";
import { DatePicker } from "./DatePicker";
import Filters from "./Filters";
import LessonsList from "./LessonsList";

export default function Schedule() {
  const { user } = useAppContext();

  return (
    <FiltersProvider>
      <TabsLayout
        header={
          <Stack pt={1} pb={2}>
            <BranchPickerContainer />
            <DatePicker />
          </Stack>
        }
      >
        {!user && (
          <AppModal open onClose={noop}>
            <Stack gap={3}>
              <Stack gap={1}>
                <Typography level="title-md">
                  <FormattedMessage defaultMessage="Пожалуйста, авторизуйтесь для просмотра расписания AMANI 💜" />
                </Typography>
                <Typography level="body-xs">
                  <FormattedMessage defaultMessage="Вход осуществляется по номеру телефона" />
                </Typography>
              </Stack>
              <Button
                component="a"
                href="/home"
                variant="solid"
                color="neutral"
              >
                <FormattedMessage defaultMessage="Авторизоваться" />
              </Button>
            </Stack>
          </AppModal>
        )}
        <Stack gap={2} pt={18} pb="calc(9rem + env(safe-area-inset-bottom))">
          <LessonsList />
          <Filters />
        </Stack>
      </TabsLayout>
    </FiltersProvider>
  );
}

import { PassStatus, UserProfile } from "@/__generated__/graphql";
import { BranchPicker } from "@/components/modals/BranchPicker/BranchPicker";
import { useAppContext } from "@/modules/app";
import { intersection, map, partition } from "lodash";
import HomeView from "../views/HomeView";

export default function Home({ user }: { user: UserProfile }) {
  const { allUpcomingLessons, allPasses } = useAppContext();

  const lessons = allUpcomingLessons
    .filter(
      (lesson) =>
        intersection(map(allPasses, "recId"), lesson.bookingsRecIds).length > 0
    )
    .slice(0, 2);

  const [activePasses, deactivatedPasses] = partition(
    allPasses,
    (pass) => pass.status === PassStatus.Active
  );

  const passes =
    activePasses.length > 0 ? activePasses : deactivatedPasses.slice(0, 1);

  return (
    <>
      <BranchPicker>
        {({ onOpen }) => (
          <HomeView
            user={user}
            lessons={lessons}
            passes={passes}
            onShowContactsClick={onOpen}
          />
        )}
      </BranchPicker>
    </>
  );
}

import { PassStatus, UserProfile } from "@/__generated__/graphql";
import { LessonsListView } from "@/components/lists/LessonsListView/LessonsListView";
import { PassesListView } from "@/components/lists/PassesListView/PassesListView";
import { Call, Phone } from "@mui/icons-material";
import { Alert, Box, Button, IconButton, Stack, Typography } from "@mui/joy";
import { FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

export default function HomeView({
  user,
  lessons,
  passes,
  onShowContactsClick,
}: {
  user: UserProfile;
  lessons: LinkedLesson[];
  passes: PartialPass[];
  onShowContactsClick: () => void;
}) {
  return (
    <Stack gap={4} p={2} pt={4} pb={12}>
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Typography level="h3">
          <FormattedMessage
            defaultMessage={`Привет, {name}!`}
            values={{ name: user.name }}
          />
        </Typography>

        <IconButton onClick={onShowContactsClick}>
          <Call />
        </IconButton>
      </Stack>
      <Stack gap={2}>
        <Typography level="title-lg">
          <FormattedMessage defaultMessage="Ближайшие занятия" />
        </Typography>
        {lessons.length === 0 ? (
          <>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Typography color="neutral">
                <FormattedMessage defaultMessage="Нет записей" />
              </Typography>
            </Box>

            <Button variant="outlined" component={RouterLink} to="/">
              <FormattedMessage defaultMessage="Записаться" />
            </Button>
          </>
        ) : (
          <LessonsListView lessons={lessons} />
        )}
      </Stack>
      <Stack gap={2}>
        <Typography level="title-lg">
          <FormattedMessage defaultMessage="Ваши абонементы" />
        </Typography>
        <PassesListView passes={passes} />

        {passes.filter(
          (pass) =>
            ![
              PassStatus.Expired,
              PassStatus.NoHours,
              PassStatus.Refunded,
            ].includes(pass.status)
        ).length === 0 && (
          <Alert variant="outlined" size="sm">
            <Stack direction="row" sx={{ justifyContent: "space-between" }}>
              <Typography level="body-sm">
                <FormattedMessage defaultMessage="Для оформления абонемента пожалуйста обратитесь к администратору" />
              </Typography>
              <IconButton
                sx={{ px: 2 }}
                variant="soft"
                onClick={onShowContactsClick}
              >
                <Phone />
              </IconButton>
            </Stack>
          </Alert>
        )}
      </Stack>
    </Stack>
  );
}

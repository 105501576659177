import { Branch } from "@/__generated__/graphql";
import { Card, Link, Stack, Typography } from "@mui/joy";

export function BranchesListView({
  branches,
  onSubmit,
}: {
  branches: Branch[];
  onSubmit: (branch: Branch) => void;
}) {
  return (
    <Stack gap={2}>
      {branches.map((branch) => (
        <Card key={branch.recId} sx={{ gap: 0 }}>
          <Link
            onClick={() => onSubmit(branch)}
            fontWeight="title-md"
            overlay
            underline="none"
          >
            {branch.name}
          </Link>
          <Typography level="body-sm">{branch.address}</Typography>
        </Card>
      ))}
    </Stack>
  );
}

import PhoneInput from "@/components/form/PhoneInput";
import env from "@/env";
import {
  Alert,
  AspectRatio,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
  Typography,
} from "@mui/joy";
import { FormattedMessage } from "react-intl";
import AuthFormView from "./AuthFormView";

export default function SendCodeView({
  onChange,
  onSubmit,
  loading,
  value,
  error,
  signInAs,
}: {
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  loading: boolean;
  error?: string | null;
  signInAs: boolean;
}) {
  return (
    <AuthFormView>
      <AspectRatio
        ratio="1/1"
        sx={{ width: "30%", mx: "auto" }}
        variant="plain"
      >
        <img src="/logo512.png" alt="logo" width="100%" height="auto" />
      </AspectRatio>

      <Typography level="h4" component="h1">
        <b>
          <FormattedMessage defaultMessage="Добро пожаловать в AMANI!" />
        </b>
      </Typography>

      {signInAs && <Alert color="danger">Включен режим sign-in-as</Alert>}

      <FormControl error={Boolean(error)}>
        <FormLabel sx={{ mx: "auto" }}>
          <FormattedMessage defaultMessage="Введите номер телефона" />
        </FormLabel>
        <PhoneInput
          autoComplete="tel"
          name="phone"
          error={Boolean(error)}
          value={value}
          onChange={(value) => onChange(value)}
        />
        {error && (
          <FormHelperText sx={{ textAlign: "left" }}>{error}</FormHelperText>
        )}
      </FormControl>

      <Button
        type="submit"
        loading={loading}
        onClick={onSubmit}
        disabled={value.length < 6}
      >
        <FormattedMessage defaultMessage="Отправить код" />
      </Button>

      <Typography fontSize="sm" textColor="neutral.400">
        <FormattedMessage
          defaultMessage="Продолжая, вы соглашаетесь с {privacyPolicy} и {termsOfUse}"
          values={{
            privacyPolicy: (
              <Link
                href={env.REACT_APP_PRIVACY_POLICY_URL}
                textColor="primary.400"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage defaultMessage="политикой конфиденциальности" />
              </Link>
            ),
            termsOfUse: (
              <Link
                href={env.REACT_APP_TERMSOF_USE_URL}
                textColor="primary.400"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage defaultMessage="пользовательским соглашением" />
              </Link>
            ),
          }}
        />
      </Typography>
    </AuthFormView>
  );
}

import { LessonsListView } from "@/components/lists/LessonsListView/LessonsListView";
import { PassesListView } from "@/components/lists/PassesListView/PassesListView";
import { Stack, Typography } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function NoAvailablePassesView({
  pass,
  lesson,
}: {
  lesson: LinkedLesson;
  pass?: PartialPass;
}) {
  return (
    <Stack gap={4}>
      <Stack gap={1}>
        <Typography level="title-md">
          <FormattedMessage defaultMessage="Нет абонементов для записи на занятие" />
        </Typography>
        <LessonsListView lessons={[lesson]} />
      </Stack>
      {pass && (
        <Stack gap={1}>
          <Typography level="title-md">
            <FormattedMessage defaultMessage="Недостаточно срока действия/часов" />
          </Typography>
          <PassesListView passes={[pass]} showBookingHours />
        </Stack>
      )}
      <Typography level="body-md">
        <FormattedMessage defaultMessage="Пожалуйста, обратитесь к администратору для оформления абонемента" />
      </Typography>
    </Stack>
  );
}

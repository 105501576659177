import {
  Button,
  Checkbox,
  Grid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  ToggleButtonGroup,
  Typography,
  tabClasses,
  useTheme,
} from "@mui/joy";
import { FormattedMessage } from "react-intl";
import { Filters, FiltersOptions } from "../types";

export default function ScheduleFiltersView({
  options,
  onChange,
  onReset,
  onSubmit,
}: {
  options: FiltersOptions;
  onChange: (values: Partial<Filters>) => void;
  onReset: () => void;
  onSubmit: () => void;
}) {
  const theme = useTheme();

  return (
    <Stack gap={2}>
      <ToggleButtonGroup
        spacing={2}
        color="neutral"
        variant="outlined"
        value={options.timeSlots
          .filter((option) => option.selected)
          .map((option) => option.value.label)}
        onChange={(_, newValue) => {
          onChange({
            timeSlots: options.timeSlots
              .map((option) => option.value)
              .filter((slot) => newValue.includes(slot.label)),
          });
        }}
      >
        {options.timeSlots.map(({ value }) => (
          <Button
            sx={{
              flexGrow: 1,
              px: 1,
              [`&[aria-pressed="true"]`]: {
                color: theme.variants.solid.primary.color,
                backgroundColor: theme.variants.solid.primary.backgroundColor,
              },
            }}
            key={value.label}
            value={value.label}
          >
            <Stack>
              <Typography level="body-md" sx={{ color: "inherit" }}>
                {value.label}
              </Typography>
              <Typography
                level="body-xs"
                sx={{ color: "inherit", opacity: 0.6 }}
              >
                {value.from}:00-{value.to}:00
              </Typography>
            </Stack>
          </Button>
        ))}
      </ToggleButtonGroup>

      <Tabs defaultValue={0} sx={{ bgcolor: "transparent" }}>
        <TabList
          sx={{
            p: 0.5,
            gap: 0.5,
            borderRadius: "xl",
            bgcolor: "background.level1",
            [`& .${tabClasses.root}[aria-selected="true"]`]: {
              boxShadow: "sm",
              bgcolor: "background.surface",
            },
          }}
        >
          <Tab disableIndicator sx={{ width: "50%" }}>
            <FormattedMessage defaultMessage="Направления" />
          </Tab>
          <Tab disableIndicator sx={{ width: "50%" }}>
            <FormattedMessage defaultMessage="Преподаватели" />
          </Tab>
        </TabList>
        <TabPanel value={0} sx={{ height: "40vh", overflow: "scroll" }}>
          <Grid container direction="column" gap={1}>
            {options.groupTypes.map(({ value: gt, selected }) => (
              <Checkbox
                key={gt.id}
                label={`${gt.direction} ${gt.level}`}
                checked={selected}
                onChange={(event) => {
                  onChange({
                    groupTypes: options.groupTypes
                      .filter((option) =>
                        option.value.id === gt.id
                          ? event.target.checked
                          : option.selected
                      )
                      .map((option) => option.value),
                  });
                }}
              />
            ))}
          </Grid>
        </TabPanel>
        <TabPanel value={1} sx={{ height: "40vh", overflow: "scroll" }}>
          <Grid container direction="column" gap={1}>
            {options.teachers.map(({ value: t, selected }) => (
              <Checkbox
                key={t.recId}
                label={t.appName}
                checked={selected}
                onChange={(event) => {
                  onChange({
                    teachers: options.teachers
                      .filter((option) =>
                        option.value.recId === t.recId
                          ? event.target.checked
                          : option.selected
                      )
                      .map((option) => option.value),
                  });
                }}
              />
            ))}
          </Grid>
        </TabPanel>
      </Tabs>

      <Button onClick={onReset} variant="plain">
        <FormattedMessage defaultMessage="Сбросить" />
      </Button>
      <Button onClick={onSubmit}>
        <FormattedMessage defaultMessage="Применить" />
      </Button>
    </Stack>
  );
}

import { Group } from "@/__generated__/graphql";
import { KeyboardArrowRight, Replay, Schedule } from "@mui/icons-material";
import {
  Box,
  CardContent,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/joy";
import { addHours, set, setDay } from "date-fns";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

export function GroupSectionView({
  group,
  showName,
}: {
  group?: Group | null;
  showName?: boolean;
}) {
  const theme = useTheme();
  const intl = useIntl();

  if (!group) return null;

  const startTime = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: group.startTimeSeconds,
  });
  const endTime = addHours(
    set(new Date(), { hours: 0, minutes: 0, seconds: group.startTimeSeconds }),
    group.durationHours
  );

  const time = (
    <>
      {group.dayOfWeeks
        .map((day) =>
          intl
            .formatDate(setDay(new Date(), day), {
              weekday: "short",
            })
            .toUpperCase()
        )
        .join("/")}{" "}
      <FormattedMessage
        defaultMessage="{st, date, ::H:mm}-{et, date, ::H:mm}"
        values={{
          st: startTime,
          et: endTime,
        }}
      />
    </>
  );

  return (
    <ListItem>
      <ListItemButton
        component={RouterLink}
        to={`/groups/${group.recId}`}
        sx={{
          "--joy-palette-neutral-plainHoverBg": "#fff",
          "--joy-palette-neutral-plainActiveBg": "#fff",
        }}
      >
        <CardContent orientation="horizontal">
          <CardContent sx={{ alignItems: "center", flexDirection: "row" }}>
            <Box>
              <Stack direction="row" gap={1}>
                {!showName && (
                  <Replay sx={{ transform: "scale(-1,1) rotate(-45deg)" }} />
                )}
                <Stack>
                  <Typography level="body-md">
                    {showName ? `${group.name}` : time}
                  </Typography>

                  {showName ? (
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <Schedule sx={{ fontSize: theme.fontSize.md }} />
                      <Typography level="body-sm" textColor="neutral.400">
                        {group.branch?.name && `${group.branch.name}, `}
                        {time}
                      </Typography>
                    </Stack>
                  ) : (
                    <Typography level="body-sm" textColor="neutral.400">
                      <FormattedMessage defaultMessage="Постоянных учениц:" />{" "}
                      {group.autoBookingPassesRecIds.length}/{group.maxSeats}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Box>
          </CardContent>
        </CardContent>
        <KeyboardArrowRight sx={{ fontSize: theme.fontSize.xl3 }} />
      </ListItemButton>
    </ListItem>
  );
}

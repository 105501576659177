import { differenceInCalendarDays } from "date-fns";

export const getAvailablePassesForLessonBooking = (
  allPasses: PartialPass[],
  lesson: LinkedLesson
): PartialPass[] => {
  return allPasses.filter((pass) => {
    return (
      pass.remainingBookingHours >= lesson.durationHours &&
      (!pass.activationDate ||
        differenceInCalendarDays(pass.deactivationDate, lesson.startDateTime) >=
          0)
    );
  });
};

import { PageLayout } from "@/components/layouts/PageLayout";
import { useIntl } from "react-intl";
import GroupContainer from "./containers/Group";

export default function GroupPage() {
  const intl = useIntl();

  return (
    <PageLayout title={intl.formatMessage({ defaultMessage: "Группа" })}>
      <GroupContainer />
    </PageLayout>
  );
}

import { Button, useTheme } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function CancelLessonButtonView({
  onClick,
  loading,
}: {
  onClick: () => void;
  loading: boolean;
}) {
  const theme = useTheme();

  return (
    <Button
      onClick={onClick}
      loading={loading}
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: theme.zIndex.badge,
        backgroundColor: "white",
        borderRadius: 0,
        borderWidth: `1px 0 0 0`,
        padding: 2,
      }}
      size="md"
      variant="outlined"
    >
      <FormattedMessage defaultMessage="Отменить запись" />
    </Button>
  );
}

import DescriptionSectionView from "@/components/sections/DescriptionSectionView";
import { GroupSectionView } from "@/components/sections/GroupSectionView";
import { LessonsSectionView } from "@/components/sections/LessonsSectionView";
import { MediaSectionView } from "@/components/sections/MediaSectionView";
import { Avatar, Box, CardContent, Stack, Typography } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function TeacherView({
  teacher,
  upcomingLessons,
  activeGroups,
}: {
  teacher: Teacher;
  upcomingLessons: LinkedLesson[];
  activeGroups: LinkedGroup[];
}) {
  return (
    <Stack gap={3}>
      <CardContent orientation="horizontal" sx={{ alignItems: "center" }}>
        {teacher.avatar && (
          <Avatar
            sx={{ mr: 2, height: "3rem", width: "3rem" }}
            src={teacher.avatar.thumbnail || teacher.avatar.url}
          />
        )}
        <CardContent sx={{ alignItems: "center", flexDirection: "row" }}>
          <Box>
            <Typography level="h3">
              <b>{teacher.appName}</b>
            </Typography>
            {teacher.appTitle && (
              <Typography level="body-md" textColor="neutral.400">
                {teacher.appTitle}
              </Typography>
            )}
          </Box>
        </CardContent>
      </CardContent>
      <MediaSectionView media={teacher.media} />
      <Stack gap={1}>
        {activeGroups.length && (
          <>
            <Typography level="h4">
              <FormattedMessage defaultMessage="Группы" />
            </Typography>
            {activeGroups.map((group) => (
              <GroupSectionView key={group.recId} group={group} showName />
            ))}
          </>
        )}
      </Stack>
      <LessonsSectionView lessons={upcomingLessons} />

      <DescriptionSectionView
        content={teacher.description}
        label={
          <Typography level="h4">
            <FormattedMessage defaultMessage="О преподавателе" />
          </Typography>
        }
      />
    </Stack>
  );
}

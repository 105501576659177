import { PageLayout } from "@/components/layouts/PageLayout";
import { useIntl } from "react-intl";
import Lesson from "./containers/Lesson";

export default function LessonPage() {
  const intl = useIntl();

  return (
    <PageLayout title={intl.formatMessage({ defaultMessage: "Занятие" })}>
      <Lesson />
    </PageLayout>
  );
}

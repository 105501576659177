import { LessonsListView } from "@/components/lists/LessonsListView/LessonsListView";
import { Stack } from "@mui/joy";

export function ScheduleLessonsListView({
  lessons,
}: {
  lessons: LinkedLesson[];
}) {
  return (
    <Stack px={2}>
      <LessonsListView lessons={lessons} hideBranch />
    </Stack>
  );
}

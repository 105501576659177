import { Lesson, usePassQuery } from "@/__generated__/graphql";
import LoadingView from "@/components/layouts/LoadingView";
import NotFoundView from "@/components/layouts/NotFoundView";
import { mapLessons, useAppContext } from "@/modules/app";
import { useParams } from "react-router-dom";
import PassView from "../views/PassView";

export default function Pass() {
  const { passId } = useParams<{ passId: string }>();
  const { allBranches, allPasses, allUpcomingLessons } = useAppContext();

  const { data, loading, error } = usePassQuery({
    variables: {
      recId: String(passId),
    },
  });

  if (loading) return <LoadingView />;

  if (error || !data) return <NotFoundView />;

  const mapLessonsList = (lessons: Lesson[]) =>
    mapLessons(
      allBranches,
      data.pass.teachers,
      data.pass.groups,
      lessons,
      allPasses
    );

  const upcomingLessons = allUpcomingLessons.filter((lesson) =>
    lesson.bookingsRecIds.includes(data.pass.pass.recId)
  );

  const pass = {
    ...data.pass.pass,
    upcomingLessons: upcomingLessons,
    visitedLessons: mapLessonsList(data.pass.pass.visitedLessons),
    penaltiedLessons: mapLessonsList(data.pass.pass.penaltiedLessons),
  };

  return <PassView pass={pass} />;
}

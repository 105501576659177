import { TabsLayout } from "@/components/layouts/TabsLayout";
import { useAppContext } from "@/modules/app/providers/AppContextProvider";
import Auth from "../auth";
import Home from "./containers/Home";

export default function HomePage() {
  const { user } = useAppContext();

  if (!user) {
    return (
      <TabsLayout>
        <Auth />
      </TabsLayout>
    );
  }

  return (
    <TabsLayout>
      <Home user={user} />
    </TabsLayout>
  );
}

import { Card, CardContent, Stack, Typography } from "@mui/joy";

export default function PassDetailItemView({
  title,
  secondaryText,
  endDecorator,
}: {
  title: string | React.ReactNode;
  secondaryText?: string;
  endDecorator?: React.ReactNode;
}) {
  return (
    <Card>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack>
            <Typography level="title-md">{title}</Typography>
            {secondaryText && (
              <Typography textColor="neutral.600">{secondaryText}</Typography>
            )}
          </Stack>
          {endDecorator && <div>{endDecorator}</div>}
        </Stack>
      </CardContent>
    </Card>
  );
}

import PageTabs from "@/components/layouts/PageTabs";
import { PassesListView } from "@/components/lists/PassesListView/PassesListView";
import { TabPanel } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function UserPassesView({
  activePasses,
  deactivatedPasses,
}: {
  activePasses: PartialPass[];
  deactivatedPasses: PartialPass[];
}) {
  return (
    <PageTabs
      tabs={[
        <FormattedMessage defaultMessage="Активные" />,
        <FormattedMessage defaultMessage="Прошедшие" />,
      ]}
    >
      <TabPanel value={0} sx={{ px: 0 }}>
        <PassesListView passes={activePasses} />
      </TabPanel>
      <TabPanel value={1} sx={{ px: 0 }}>
        <PassesListView passes={deactivatedPasses} />
      </TabPanel>
    </PageTabs>
  );
}

import { Button } from "@mui/joy";
import { KeyboardArrowDown } from "@mui/icons-material";

export default function BranchPickerView({
  value,
  onClick,
}: {
  value: string;
  onClick: () => void;
}) {
  return (
    <Button
      onClick={onClick}
      fullWidth
      variant="plain"
      color="neutral"
      sx={{
        borderRadius: 0,
      }}
      endDecorator={<KeyboardArrowDown />}
    >
      {value}
    </Button>
  );
}

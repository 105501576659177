import { PageLayout } from "@/components/layouts/PageLayout";
import { useAppContext } from "@/modules/app";
import { useIntl } from "react-intl";
import { Navigate } from "react-router-dom";
import Pass from "./containers/Pass";

export default function PassPage() {
  const { user } = useAppContext();
  const intl = useIntl();

  if (!user) {
    return <Navigate to="/home" />;
  }

  return (
    <PageLayout title={intl.formatMessage({ defaultMessage: "Абонемент" })}>
      <Pass />
    </PageLayout>
  );
}

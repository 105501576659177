import { AppModal } from "@/components/modals/AppModal/AppModal";
import { useAppContext } from "@/modules/app/providers/AppContextProvider";
import { useState } from "react";
import BranchesContractsView from "../views/BranchesContractsView";
import SchoolRulesView from "../views/SchoolRulesView";

export default function SchoolRules() {
  const { location, allBranches } = useAppContext();
  const [open, setOpen] = useState(false);

  return (
    <>
      <SchoolRulesView
        location={location}
        onShowContractsClick={() => setOpen(true)}
      />
      <AppModal open={open} onClose={() => setOpen(false)}>
        <BranchesContractsView
          branches={allBranches}
          onClose={() => setOpen(false)}
        />
      </AppModal>
    </>
  );
}

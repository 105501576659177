import env from "@/env";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import * as React from "react";
import { IMaskInput } from "react-imask";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const MASK = {
  by: "+375 (00) 000-00-00",
  ae: "+971 00 000 0000",
}[env.REACT_APP_PHONE_COUNTRY_SYMBOL];

const TextMaskAdapter = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskAdapter(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={MASK}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }
);

export default function PhoneInput({
  value,
  onChange,
  disabled,
  error,
  name,
  autoComplete,
}: {
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  error?: boolean;
  name?: string;
  autoComplete?: string;
}) {
  return (
    <FormControl>
      <Input
        autoComplete={autoComplete}
        name="name"
        value={value}
        onChange={(event) => onChange?.(event.target.value)}
        placeholder={MASK}
        slotProps={{ input: { component: TextMaskAdapter } }}
        disabled={disabled}
        error={error}
      />
    </FormControl>
  );
}

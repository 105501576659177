import { Branch } from "@/__generated__/graphql";
import { Link } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/joy";
import { FormattedMessage } from "react-intl";

export default function BranchesContractsView({
  branches,
  onClose,
}: {
  branches: Branch[];
  onClose: () => void;
}) {
  return (
    <Stack gap={1}>
      <Typography level="title-md">
        <FormattedMessage defaultMessage="Ознакомиться с договором" />
      </Typography>

      {branches.map(
        (branch) =>
          branch.contractUrl && (
            <Button
              key={branch.id}
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={branch.contractUrl}
              variant="outlined"
              startDecorator={<Link />}
            >
              {branch.name}
            </Button>
          )
      )}
    </Stack>
  );
}

import TeacherContent from "@/components/sections/TeacherContent";
import { lessonHasTeacherReplacement } from "@/modules/app";
import { Box, Card, Stack, Typography } from "@mui/joy";
import { FormattedDate, FormattedMessage } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import { EmptyListView } from "../EmptyListView";
import { LessonChip } from "./LessonStatusChip";

export const LessonsListView = ({
  lessons,
  hideBranch,
}: {
  lessons: LinkedLesson[];
  hideBranch?: boolean;
}) => {
  if (lessons.length === 0) return <EmptyListView />;

  return (
    <Stack gap={2}>
      {lessons.map((lesson) => (
        <Card
          component={RouterLink}
          to={`/lessons/${lesson.recId}`}
          key={lesson.recId}
          sx={{
            gap: 1,
            bgcolor: "white",
            textDecoration: "none",
          }}
        >
          <Box>
            <Typography level="title-md">{lesson.group?.name}</Typography>
            <Typography level="body-sm">
              <FormattedMessage
                defaultMessage="{st, date, ::H:mm}-{et, date, ::H:mm}"
                values={{
                  st: lesson.startDateTime,
                  et: lesson.endDateTime,
                }}
              />

              {" · "}
              <FormattedDate
                value={lesson.startDateTime}
                month="short"
                day="numeric"
                weekday="long"
              />

              {!hideBranch && (
                <>
                  {" · "}
                  {lesson.group?.branch?.name}
                </>
              )}
            </Typography>
          </Box>
          <TeacherContent
            teacher={lesson.teacher}
            replacedTeacher={
              lessonHasTeacherReplacement(lesson) && lesson.group?.teacher
            }
          />
          <LessonChip lesson={lesson} />
        </Card>
      ))}
    </Stack>
  );
};

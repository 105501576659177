import { ArrowBackIos } from "@mui/icons-material";
import { Box, Grid, IconButton, Stack } from "@mui/joy";
import React from "react";

export default function AuthFormView({
  children,
  onBack,
}: React.PropsWithChildren<{
  onBack?: () => void;
}>) {
  return (
    <Grid
      container
      sx={{
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
        pb: 8,
      }}
    >
      <Box display="flex" alignItems="center" height="100%">
        {onBack && (
          <IconButton
            onClick={onBack}
            sx={{ position: "absolute", left: 0, top: 0, p: 2 }}
          >
            <ArrowBackIos />
          </IconButton>
        )}
        <Stack
          sx={{
            width: 300,
            mx: "auto",
            py: 3,
            px: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            textAlign: "center",
          }}
        >
          {children}
        </Stack>
      </Box>
    </Grid>
  );
}

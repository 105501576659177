import { SignInMode } from "@/modules/app";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Typography,
} from "@mui/joy";
import { FormattedMessage } from "react-intl";
import AuthFormView from "./AuthFormView";

export default function SignInView({
  code,
  phone,
  onChangeCode,
  onSubmit,
  loading,
  onBack,
  error,
  signInMode,
}: {
  phone: string;
  code: string;
  onChangeCode: (value: string) => void;
  onSubmit: () => void;
  loading: boolean;
  error?: string | null;
  onBack: () => void;
  signInMode: SignInMode;
}) {
  return (
    <AuthFormView onBack={onBack}>
      <Typography>
        {signInMode === SignInMode.sms && (
          <FormattedMessage
            defaultMessage="Мы отправили код на номер {phone}"
            values={{
              phone: <Typography whiteSpace="nowrap">{phone}</Typography>,
            }}
          />
        )}
        {signInMode === SignInMode.signInAs && (
          <FormattedMessage defaultMessage="Для входа требуется системный код sign-in-as" />
        )}
        {signInMode === SignInMode.identifier && (
          <FormattedMessage defaultMessage="Код для входа можно узнать у администратора" />
        )}
      </Typography>

      <FormControl error={Boolean(error)}>
        <FormLabel sx={{ mx: "auto" }}>
          <FormattedMessage defaultMessage="Введите код" />
        </FormLabel>

        <Input
          type="text"
          value={code}
          error={Boolean(error)}
          onChange={(e) => onChangeCode(e.target.value)}
          slotProps={{
            input: {
              autoComplete: "one-time-code",
              inputMode: "numeric",
              pattern: "[0-9]+",
            },
          }}
        />
        {error && (
          <FormHelperText sx={{ textAlign: "left" }}>{error}</FormHelperText>
        )}
      </FormControl>

      <Button
        type="submit"
        loading={loading}
        onClick={onSubmit}
        disabled={code.length < 4}
      >
        <FormattedMessage defaultMessage="Войти" />
      </Button>
    </AuthFormView>
  );
}

import { useBookLessonMutation } from "@/__generated__/graphql";
import { AppModal } from "@/components/modals/AppModal/AppModal";
import { extractGraphQLErrorMessage } from "@/helpers";
import { useAppContext } from "@/modules/app";
import Auth from "@/modules/auth";
import { isBefore } from "date-fns";
import { maxBy } from "lodash";
import { useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { getAvailablePassesForLessonBooking } from "../helpers/getAvailablePassesForLessonBooking";
import BookLessonAuthView from "../views/booking/BookLessonAuthView";
import BookLessonButtonView from "../views/booking/BookLessonButtonView";
import ChoosePassView from "../views/booking/BookLessonChoosePassView";
import NoAvailablePassesView from "../views/booking/NoAvailablePassesView";

export default function BookLesson({ lesson }: { lesson: LinkedLesson }) {
  const { allPasses, user, location } = useAppContext();
  const intl = useIntl();
  const [openChoosePass, setOpenChoosePass] = useState(false);
  const [openNoAvailablePasses, setOpenNoAvailablePasses] = useState(false);
  const [openNeedsAuth, setOpenNeedsAuth] = useState(false);

  const [bookLesson, { loading }] = useBookLessonMutation();

  const onClick = () => {
    const passes = allPasses
      ? getAvailablePassesForLessonBooking(allPasses, lesson)
      : [];

        if (passes.length) {
      setOpenChoosePass(true);
    } else if (user) {
      setOpenNoAvailablePasses(true);
    } else {
      setOpenNeedsAuth(true);
    }
  };

  const onBook = (pass: PartialPass) => {
    setOpenChoosePass(false);

    bookLesson({
      variables: {
        lessonRecId: lesson.recId,
        passRecId: pass.recId,
      },
    })
      .then(() => {
        toast.success(
          intl.formatMessage({
            defaultMessage: "Вы успешно записаны на занятие!",
          })
        );
      })
      .catch((error) => {
        toast.error(extractGraphQLErrorMessage(error));
      });
  };

  if (
    !lesson.bookingAllowed ||
    lesson.remainingSeats <= 0 ||
    lesson.passRecId ||
    isBefore(lesson.startDateTime, new Date())
  )
    return null;

  return (
    <>
      <BookLessonButtonView onClick={onClick} loading={loading} />
      <AppModal open={openChoosePass} onClose={() => setOpenChoosePass(false)}>
        <ChoosePassView
          onClose={() => setOpenChoosePass(false)}
          passes={getAvailablePassesForLessonBooking(allPasses || [], lesson)}
          onChoose={onBook}
          rules={location.lessonsRules}
        />
      </AppModal>
      <AppModal
        open={openNoAvailablePasses}
        onClose={() => setOpenNoAvailablePasses(false)}
      >
        <NoAvailablePassesView
          lesson={lesson}
          pass={maxBy(allPasses, (pass) => pass.activationDate)}
        />
      </AppModal>
      <AppModal open={openNeedsAuth} onClose={() => setOpenNeedsAuth(false)}>
        <BookLessonAuthView>
          <Auth />
        </BookLessonAuthView>
      </AppModal>
    </>
  );
}

import { Group, Teacher, useLessonQuery } from "@/__generated__/graphql";
import LoadingView from "@/components/layouts/LoadingView";
import NotFoundView from "@/components/layouts/NotFoundView";
import { mapLessons, useAppContext } from "@/modules/app";
import { useParams } from "react-router-dom";
import LessonView from "../views/LessonView";
import BookLesson from "./BookLesson";
import CancelLesson from "./CancelLesson";

function LessonRenderer({ lesson }: { lesson: LinkedLesson }) {
  return (
    <LessonView lesson={lesson}>
      <CancelLesson lesson={lesson} />
      <BookLesson lesson={lesson} />
    </LessonView>
  );
}

export default function Lesson() {
  const { allUpcomingLessons, allBranches, allPasses } = useAppContext();
  const { lessonId } = useParams<{ lessonId: string }>();

  const cachedLesson = allUpcomingLessons.find(
    (lesson) => lesson.recId === lessonId
  );

  const { data, loading, error } = useLessonQuery({
    variables: {
      recId: String(lessonId),
      hasCached: Boolean(cachedLesson),
    },
  });

  if (cachedLesson) return <LessonRenderer lesson={cachedLesson} />;

  if (loading) return <LoadingView />;

  if (error || !data?.lesson) return <NotFoundView />;

  const lesson = mapLessons(
    allBranches,
    [data.lesson.teacher].filter(Boolean) as Teacher[],
    [data.lesson.group].filter(Boolean) as Group[],
    [data.lesson],
    allPasses
  )[0];

  return <LessonRenderer lesson={lesson} />;
}

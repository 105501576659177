import { PageLayout } from "@/components/layouts/PageLayout";
import { useIntl } from "react-intl";
import SchoolRules from "./containers/SchoolRules";

export default function SettingRulesPage() {
  const intl = useIntl();

  return (
    <PageLayout
      title={intl.formatMessage({
        defaultMessage: "Правила школы",
      })}
    >
      <SchoolRules />
    </PageLayout>
  );
}
